import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@massds/mayflower-react';
import {
  ComposableMap, ZoomableGroup, Geographies, Geography, Annotations, Annotation
} from 'react-simple-maps';
import { scaleThreshold } from 'd3-scale';
import ReactTooltip from 'react-tooltip';
import { Motion, spring } from 'react-motion';
import IconButton from '../Button';
import LegendThreshold from '../Legend/Threshold';
import { hexToHSL } from '../../utils';
import './index.css';

class ThresholdScaleMap extends Component {
  constructor(props) {
    super(props);
    const {
      defaultCenter, defaultDisablePan, defaultZoom, areas
    } = this.props;
    this.state = {
      areaName: '',
      center: defaultCenter,
      zoom: defaultZoom,
      disablePan: defaultDisablePan,
      areas,
      renderAnnotations: true
    };
    this.handleReset = this.handleReset.bind(this);
    this.handleZoomIn = this.handleZoomIn.bind(this);
    this.handleZoomOut = this.handleZoomOut.bind(this);
    this.handleMoveEnd = this.handleMoveEnd.bind(this);
    this.handleAreaSelection = this.handleAreaSelection.bind(this);
  }

  handleReset() {
    const {
      defaultCenter, defaultDisablePan, defaultZoom
    } = this.props;
    const { center, panCenter } = this.state;
    if (panCenter && panCenter !== center) {
      this.setState({
        center: panCenter
      }, () => {
        this.setState({
          center: defaultCenter,
          zoom: defaultZoom,
          disablePan: defaultDisablePan,
          panCenter: defaultCenter,
          renderAnnotations: true
        });
      });
    } else {
      this.setState({
        center: defaultCenter,
        zoom: defaultZoom,
        disablePan: defaultDisablePan,
        panCenter: defaultCenter,
        renderAnnotations: true
      });
    }
  }

  handleZoomIn() {
    const { zoom, panCenter, center } = this.state;
    const newZoom = zoom < 10 ? Number(zoom) + 1 : zoom;
    if (panCenter && panCenter !== center) {
      this.setState({
        center: panCenter
      }, () => {
        this.setState({
          areaName: '',
          zoom: newZoom,
          disablePan: false,
          renderAnnotations: false
        });
      });
    } else {
      this.setState({
        areaName: '',
        zoom: newZoom,
        disablePan: false,
        renderAnnotations: false
      });
    }
  }

  handleZoomOut() {
    const {
      zoom, panCenter, center
    } = this.state;
    const {
      defaultZoom, defaultCenter, defaultDisablePan
    } = this.props;
    const newZoom = zoom - 1;
    if (panCenter && panCenter !== center) {
      this.setState({
        center: panCenter
      }, () => {
        if (newZoom <= defaultZoom) {
          this.setState({
            areaName: '',
            center: defaultCenter,
            zoom: defaultZoom,
            disablePan: defaultDisablePan,
            renderAnnotations: false
          });
        } else {
          this.setState({
            areaName: '',
            zoom: newZoom,
            disablePan: false
          });
        }
      });
    } else if (newZoom <= defaultZoom) {
      this.setState({
        areaName: '',
        center: defaultCenter,
        zoom: defaultZoom,
        disablePan: defaultDisablePan,
        renderAnnotations: false
      });
    } else {
      this.setState({
        areaName: '',
        zoom: newZoom,
        disablePan: false
      });
    }
  }

  handleMoveEnd(center) {
    this.setState({
      panCenter: center
    });
  }

  handleAreaSelection(area) {
    this.setState({
      areaName: area.name,
      center: area.coordinates,
      zoom: area.zoom,
      disablePan: false,
      renderAnnotations: false
    });
  }

  render() {
    const {
      areas, center, zoom, disablePan, areaName, renderAnnotations
    } = this.state;
    const {
      allData, geography, showZoom, geoDisplayField, scaleBreaks, scaleColors, dataFormat, dataField, dataUnits, legend, tooltip, nullValue, nullValueMessage, annotations
    } = this.props;
    if (allData.length === 0) {
      return<div className="container">Loading...</div>;
    }

    const colorScale = scaleThreshold();
    colorScale.domain(scaleBreaks).range(scaleColors);

    return(
      <div>
        {(showZoom || areas) && (
        <div className={`wrapperButtonStyles ${legend.direction}`}>
          {
            areas && areas.map((area, i) => (
              <Button
                size="small"
                info={`Zoom to ${area.name} area on map`}
                text={area.name}
                onClick={() => this.handleAreaSelection(area)}
                usage="quaternary"
                classes={areaName !== area.name ? ['inactive'] : ['']}
                // eslint-disable-next-line react/no-array-index-key
                key={`originMap_button-${i}`}
              />
            ))
          }
          <div className="map-button-controls-general">
            { showZoom && (
              <div className="button-zoom-container">
                <IconButton
                  size="small"
                  info="Zoom in"
                  onClick={this.handleZoomIn}
                  icon="zoom-in"
                  classes={['button-zoom']}
                />
                <IconButton
                  size="small"
                  info="Zoom out"
                  onClick={this.handleZoomOut}
                  icon="zoom-out"
                  classes={['button-zoom']}
                />
                {!areas && (
                  <IconButton
                    size="small"
                    info="Zoom out to full state"
                    text="Reset"
                    onClick={this.handleReset}
                    icon="refresh"
                    classes={['button-zoom']}
                  />
                )}
              </div>
            )}
            <IconButton
              size="small"
              info="Zoom out to full state"
              text="Reset"
              onClick={this.handleReset}
              icon="refresh"
              classes={['button-zoom']}
            />
          </div>
        </div>
        )}
        <div className={`wrapperStyles ${legend.direction}`}>
          {!legend.showLegend && legend.direction === 'row' && (
          <div className={`wrapperLegendStyles ${legend.direction}`}>
            <span className="legendTitle">{legend.title}</span>
            <LegendThreshold
              scale={colorScale}
              direction={legend.direction || 'column'}
              labelMargin={legend.direction === 'row' ? '1px' : '0 5px 0 0'}
              shape="rect"
              labelFormat={dataFormat}
              itemDirection={legend.itemDirection || 'row'}
              shapeWidth={legend.direction === 'row' && '100%'}
              shapeMargin={legend.direction === 'row' && '1px'}
            />
          </div>
          )}
          <div className={`wrapperMapStyles ${legend.direction}`}>
            <Motion
              defaultStyle={{
                zoom,
                x: center[0],
                y: center[1]
              }}
              style={{
                zoom: spring(zoom, { stiffness: 200, damping: 30 }),
                x: spring(center[0], { stiffness: 200, damping: 30 }),
                y: spring(center[1], { stiffness: 200, damping: 30 })
              }}
            >
              { /* eslint-disable no-shadow */
              // eslint-disable-next-line no-unused-vars
              ({ zoom, x, y }) => (
                <ComposableMap
                  projection="mercator"
                  projectionConfig={{
                    scale: 12000
                  }}
                  width={980}
                  height={530}
                  style={{
                    width: '100%',
                    height: 'auto'
                  }}
                >
                  <ZoomableGroup center={center[0] === x && center[1] === y ? center : [x, y]} zoom={zoom} disablePanning={disablePan} onMoveEnd={this.handleMoveEnd}>
                    <Geographies geography={geography} disableOptimization>
                      {(geographies, projection) => geographies.map((geo, i) => {
                        const townData = allData.find((s) => s.FIPS_CODE === geo.id) || {};
                        const geoLabel = townData[geoDisplayField];
                        const metric = townData[dataField];
                        const fill = colorScale(metric);
                        const tooltipMetric = metric && dataUnits ? `${dataFormat(metric)} ${dataUnits}` : dataFormat(metric);
                        const tooltipVal = metric && (Number(metric) !== nullValue) ? tooltipMetric : nullValueMessage;
                        const fillHSL = hexToHSL(fill);
                        let strokeColor = '#ffffff';
                        if (Number(metric) === nullValue) {
                          strokeColor = '#ffffff';
                        } else if (fillHSL.l && Number(fillHSL.l) > 85) {
                          strokeColor = '#ddd';
                        }
                        const dataTip = tooltip.dataLabel ? `<strong>${geoLabel}</strong><br><strong>${tooltip.dataLabel}</strong>: ${tooltipVal}` : `<strong>${geoLabel}</strong><br>${tooltipVal}`;
                        return(
                          <Geography
                        // eslint-disable-next-line react/no-array-index-key
                            key={`${i}two`}
                            cacheId={`${i}two`}
                            round
                            geography={geo}
                            projection={projection}
                            data-html
                            data-tip={dataTip}
                            style={{
                              default: {
                                fill: (Number(metric) === nullValue) ? '#eaeaea' : fill,
                                stroke: strokeColor,
                                strokeWidth: 0.5,
                                outline: 'none'
                              },
                              hover: {
                                fill: (Number(metric) === nullValue) ? '#eaeaea' : fill,
                                stroke: 'rgba(255,255,255,1.0)',
                                fillOpacity: '0.7',
                                strokeWidth: 2.00,
                                outline: 'true'
                              },
                              pressed: {
                                fill: (Number(metric) === nullValue) ? '#eaeaea' : fill,
                                stroke: 'rgba(255,255,255,1.0)',
                                fillOpacity: '0.3',
                                strokeWidth: 2.00,
                                outline: 'true'
                              }
                            }}
                          />
                        );
                      })}
                    </Geographies>
                    {renderAnnotations && annotations && (
                    <Annotations>
                      {annotations.map((annotation, index) => {
                        const {
                          dx, dy, coordinates, strokeWidth, label
                        } = annotation;
                        return(
                          <Annotation
                            dx={dx}
                            dy={dy}
                            subject={coordinates}
                            strokeWidth={strokeWidth}
                            stroke="black"
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${label}-${index}`}
                          >
                            <text>{label}</text>
                          </Annotation>
                        );
                      })}
                    </Annotations>
                    )}
                  </ZoomableGroup>
                </ComposableMap>
              )}
            </Motion>
            <ReactTooltip type={tooltip.type} />
          </div>
          {!legend.showLegend && legend.direction === 'column' && (
          <div className={`wrapperLegendStyles ${legend.direction}`}>
            <span className="legendTitle">{`${legend.title}`}</span>
            <LegendThreshold
              scale={colorScale}
              direction={legend.direction || 'column'}
              labelMargin="0 5px 0 0"
              shape="rect"
              labelFormat={dataFormat}
              nullLabel={nullValueMessage}
              nullColor={() => '#eaeaea'}
            />
          </div>
          )}
        </div>
      </div>
    );
  }
}

ThresholdScaleMap.propTypes = {
  allData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  geography: PropTypes.object,
  defaultCenter: PropTypes.array,
  defaultZoom: PropTypes.number,
  defaultDisablePan: PropTypes.bool,
  showZoom: PropTypes.bool,
  geoDisplayField: PropTypes.string,
  scaleBreaks: PropTypes.array,
  scaleColors: PropTypes.array,
  dataFormat: PropTypes.func,
  dataField: PropTypes.string,
  dataUnits: PropTypes.string,
  areas: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    coordinates: PropTypes.array,
    zoom: PropTypes.number
  })),
  legend: PropTypes.shape({
    title: PropTypes.string,
    direction: PropTypes.oneOf(['row', 'column']),
    itemDirection: PropTypes.oneOf(['row', 'column']),
    showLegend: PropTypes.bool
  }),
  tooltip: PropTypes.shape({
    dataLabel: PropTypes.string,
    type: PropTypes.oneOf(['light', 'dark'])
  }),
  nullValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nullValueMessage: PropTypes.string,
  annotations: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    strokeWidth: PropTypes.number,
    coordinates: PropTypes.array,
    dx: PropTypes.number,
    dy: PropTypes.number
  }))
};

ThresholdScaleMap.defaultProps = {
  legend: {
    direction: 'column',
    itemDirection: 'row',
    showLegend: true
  },
  tooltip: {
    type: 'light'
  },
  geoDisplayField: 'TOWN',
  defaultCenter: [-71.63627925574303, 42.13276308419552],
  defaultZoom: 1,
  defaultDisablePan: true,
  nullValueMessage: 'No Data'
};

export default ThresholdScaleMap;
