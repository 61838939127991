import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import TagManager from 'react-gtm-module';
import { round, toProperCase } from '../../utils';

import './index.css';

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data
    };
  }

  render() {
    const { data } = this.state;
    const {
      headRow, pageSize, pagination, id
    } = this.props;
    const columnsConfig = (header) => [
      {
        Header: id,
        id,
        className: 'left string',
        accessor: (d) => d[header],
        filterMethod: (filter, row) => row[filter.id].startsWith(filter.value.toUpperCase()),
        Cell: (row) => toProperCase(row.value),
        Filter: ({ filter, onChange }) => (
          <input
            placeholder="Search by municipality..."
            value={filter ? filter.value : ''}
            onChange={(event) => onChange(event.target.value)}
          />
        )
      },
      {
        Header: 'Rides started',
        id: 'originTrips',
        className: 'right number',
        accessor: (d) => Number(d.ORIGIN_TRIPS_2019),
        Cell: (row) => (<div>{row.value.toLocaleString()}</div>),
        filterable: false
      },
      {
        Header: 'Rides ended',
        id: 'destinationTrips',
        className: 'right number',
        accessor: (d) => Number(d.DESTINATION_TRIPS_2019),
        Cell: (row) => (<div>{row.value.toLocaleString()}</div>),
        filterable: false
      },
      {
        Header: 'Rides started per person',
        id: 'originTripsPerCap',
        className: 'right number',
        accessor: (d) => round(Number(d.ORIGIN_TRIPS_PP_2019), 2),
        filterable: false
      },
      {
        Header: 'Rides ended per person',
        id: 'destinationTripsPerCap',
        className: 'right number',
        accessor: (d) => round(Number(d.DESTINATION_TRIPS_PP_2019), 2),
        filterable: false
      }
    ];
    return(
      <ReactTable
        data={data}
        filterable
        columns={columnsConfig(headRow)}
        defaultPageSize={pageSize}
        defaultSorted={[
          {
            id: 'originTrips',
            desc: true
          }
        ]}
        // getPaginationProps={(props) => console.log(props)}
        className="-striped -highlight"
        showPaginationBottom={pagination}
        onPageChange={(index) => {
          TagManager.dataLayer({
            dataLayer: {
              event: 'gtm.data-table-action',
              tableaction: 'pagination',
              tableactionvalue: index,
              tableid: id
            }
          });
        }}
        onPageSizeChange={(size) => {
          TagManager.dataLayer({
            dataLayer: {
              event: 'gtm.data-table-action',
              tableaction: 'pagesize',
              tableactionvalue: size,
              tableid: id
            }
          });
        }}
        onSortedChange={(sortby) => {
          if (sortby[0] && sortby[0].id) {
            TagManager.dataLayer({
              dataLayer: {
                event: 'gtm.data-table-action',
                tableaction: 'sort-column',
                tableactionvalue: `${sortby[0].id} (${sortby[0].desc ? 'desc' : 'asc'})`,
                tableid: id
              }
            });
          }
        }}
        onFilteredChange={(filter) => {
          if (filter[0] && filter[0].id && filter[0].value) {
            TagManager.dataLayer({
              dataLayer: {
                event: 'gtm.data-table-action',
                tableaction: 'filter-column',
                tableactionvalue: `${filter[0].id} by ${filter[0].value}`,
                tableid: id
              }
            });
          }
        }}
      />
    );
  }
}

Table.propTypes = {
  data: PropTypes.array,
  headRow: PropTypes.string,
  pageSize: PropTypes.number,
  pagination: PropTypes.bool,
  id: PropTypes.string
};

export default Table;
