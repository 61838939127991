import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import additonalProps from './util/additionalProps';

LegendItem.propTypes = {
  flexDirection: PropTypes.string,
  margin: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.object,PropTypes.string]).isRequired
};

export default function LegendItem({
  children,
  flexDirection,
  margin,
  label,
  ...restProps
}) {
  return(
    <div
      className={cx('vx-legend-item', flexDirection)}
      style={{
        margin
      }}
      {...additonalProps(restProps, label)}
    >
      {children}
    </div>
  );
}
