import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, CompHeading
} from '@massds/mayflower-react';

import FootNoteLink from '../components/FootNote/FootNoteLink';
import InTextButton from '../components/Button/InTextButton';
import ThresholdScaleMap from '../components/ThresholdScaleMap';

import { getObjByValue } from '../utils';
import { totalMapsOptions } from './mapOptions.data';
import content from '../data/Content.data';

const {
  data: {
    boston,
    cambridge,
    hadley,
    amherst,
    nantucket
  }
} = content;

const TotalTripsSection = (props) => {
  const {
    allData, geography, areas
  } = props;
  const [option, setOption] = useState(totalMapsOptions[0].origin);
  const setOptionFromName = (name) => {
    const opt = getObjByValue(totalMapsOptions, name, 'name');
    setOption(opt.origin);
  };

  return(
    <Fragment>
      <div className="page-content">
        <div className="map-print">
          <CompHeading title={option.title} level={3} centered />
          <div className="wrapperButtonStyles data-change">
            {
                totalMapsOptions.map((opt, i) => (
                  <Button
                    text={opt.name}
                    info={`Change map to display ${option.name}`}
                    usage={opt.origin.field === option.field ? '' : 'secondary'}
                    onClick={() => setOption(opt.origin)}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`deltamap_button-${i}`}
                  />
                ))
              }
          </div>
          <ThresholdScaleMap
            showZoom={false}
            geoDisplayField="TOWN"
            dataField={option.field}
            dataUnits={option.units}
            dataFormat={option.dataFormat}
            scaleBreaks={option.scaleBreaks}
            scaleColors={option.colorScale}
            allData={allData}
            geography={geography}
            legend={{
              direction: window.innerWidth > 620 ? 'column' : 'row',
              title: option.legendTitle,
              itemDirection: window.innerWidth > 620 ? 'row' : 'column'
            }}
            tooltip={{ dataLabel: option.dataLabel }}
            areas={areas}
            annotations={option.annotations}
            nullValueMessage=""
          />
          <p>
            {'You can also '}
            <a href="http://massgis.maps.arcgis.com/apps/View/index.html?appid=92bea4941d4c4619862d8ba7b1773615">view this map</a>
            {' with overlays for public transportation and major highways, as well as labels for colleges and universities.'}
          </p>
        </div>
      </div>
      <aside className="sidebar">
        <p>
          {'This map shows where rides '}
          <InTextButton onClick={() => setOptionFromName('Total Rides Started')}>
            began
          </InTextButton>
          {' and '}
          <InTextButton onClick={() => setOptionFromName('Total Rides Ended')}>
            ended
          </InTextButton>
          {' by both total number of rides and average number of rides per person.'}
          <FootNoteLink index={5} />
          <br />
          <br />
          {`Despite having fewer total rides, Cambridge’s ${cambridge.originPP} `}
          <InTextButton onClick={() => setOptionFromName('Rides Started Per Person')}>
            rides per person
          </InTextButton>
          {` was the highest per person count in the state  – higher even than Boston’s ${boston.originPP}.`}
          <br />
          <br />
          {`Other notable rides-per-person counts include Provincetown (18.7 rides per person) and Tisbury (11.2 rides per person) indicating again that seasonal activity (e.g. people vacationing or attending college) shape rideshare numbers. For example, Nantucket’s ${nantucket.originPP} rides per person is much higher than the numbers for comparably-populated towns. Similarly, Hadley (${hadley.originPP}) and Amherst (${amherst.originPP}) have many more rides per person than other towns in Western Massachusetts.`}
        </p>
      </aside>
    </Fragment>
  );
};

TotalTripsSection.propTypes = {
  allData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  geography: PropTypes.object,
  defaultCenter: PropTypes.array,
  defaultZoom: PropTypes.number,
  defaultDisablePan: PropTypes.bool,
  areas: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    coordinates: PropTypes.array,
    zoom: PropTypes.number
  }))
};

export default TotalTripsSection;
