export default [{
  TOWN_ID: 1,
  TOWN: 'ABINGTON',
  X_COORD: 244743.23455,
  Y_COORD: 874316.01004,
  OLD_X_LONG: -70.958931,
  OLD_Y_LAT: 42.1179861,
  X_LONG: -70.96072899,
  Y_LAT: 42.16760232,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 2,
  TOWN: 'ACTON',
  X_COORD: 205115.64893,
  Y_COORD: 914871.51664,
  OLD_X_LONG: -71.4377791,
  OLD_Y_LAT: 42.4843633,
  X_LONG: -71.43958922,
  Y_LAT: 42.53397619,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 3,
  TOWN: 'ACUSHNET',
  X_COORD: 249836.42846,
  Y_COORD: 829476.59106,
  OLD_X_LONG: -70.901149,
  OLD_Y_LAT: 41.713982,
  X_LONG: -70.90294552,
  Y_LAT: 41.76360191,
  DX: -50,
  DY: -20
}, {
  TOWN_ID: 4,
  TOWN: 'ADAMS',
  X_COORD: 67223.59625,
  Y_COORD: 931958.83466,
  OLD_X_LONG: -73.1186899,
  OLD_Y_LAT: 42.6268637,
  X_LONG: -73.12054261,
  Y_LAT: 42.67647529,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 5,
  TOWN: 'AGAWAM',
  X_COORD: 104612.03636,
  Y_COORD: 869013.24152,
  OLD_X_LONG: -72.6525821,
  OLD_Y_LAT: 42.0657193,
  X_LONG: -72.654423,
  Y_LAT: 42.115336,
  DX: 10,
  DY: 100
}, {
  TOWN_ID: 6,
  TOWN: 'ALFORD',
  X_COORD: 41081.56859,
  Y_COORD: 890478.01416,
  OLD_X_LONG: -73.4258739,
  OLD_Y_LAT: 42.2486188,
  X_LONG: -73.42773439,
  Y_LAT: 42.29823383,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 7,
  TOWN: 'AMESBURY',
  X_COORD: 245393.28357,
  Y_COORD: 955964.34197,
  OLD_X_LONG: -70.9446418,
  OLD_Y_LAT: 42.852968,
  X_LONG: -70.94643942,
  Y_LAT: 42.90257753,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 8,
  TOWN: 'AMHERST',
  X_COORD: 117069.09173,
  Y_COORD: 902039.35022,
  OLD_X_LONG: -72.5067873,
  OLD_Y_LAT: 42.3644504,
  X_LONG: -72.50862451,
  Y_LAT: 42.41406438,
  DX: -10,
  DY: 125
}, {
  TOWN_ID: 9,
  TOWN: 'ANDOVER',
  X_COORD: 227470.81715,
  Y_COORD: 932942.3823,
  OLD_X_LONG: -71.165015,
  OLD_Y_LAT: 42.646574,
  X_LONG: -71.16681821,
  Y_LAT: 42.69618541,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 10,
  TOWN: 'ARLINGTON',
  X_COORD: 227692.34933,
  Y_COORD: 907626.69485,
  OLD_X_LONG: -71.1635314,
  OLD_Y_LAT: 42.4186662,
  X_LONG: -71.16533457,
  Y_LAT: 42.46827968,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 11,
  TOWN: 'ASHBURNHAM',
  X_COORD: 165137.65608,
  Y_COORD: 934358.35994,
  OLD_X_LONG: -71.9252035,
  OLD_Y_LAT: 42.6590241,
  X_LONG: -71.92702597,
  Y_LAT: 42.70863539,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 12,
  TOWN: 'ASHBY',
  X_COORD: 173510.98303,
  Y_COORD: 936266.10101,
  OLD_X_LONG: -71.8231664,
  OLD_Y_LAT: 42.6765281,
  X_LONG: -71.82498628,
  Y_LAT: 42.72613923,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 13,
  TOWN: 'ASHFIELD',
  X_COORD: 92639.52892,
  Y_COORD: 919674.61582,
  OLD_X_LONG: -72.8066023,
  OLD_Y_LAT: 42.520217,
  X_LONG: -72.8084471,
  Y_LAT: 42.56982956,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 14,
  TOWN: 'ASHLAND',
  X_COORD: 202592.48413,
  Y_COORD: 889643.8614,
  OLD_X_LONG: -71.468581,
  OLD_Y_LAT: 42.2572574,
  X_LONG: -71.4703919,
  Y_LAT: 42.30687235,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 15,
  TOWN: 'ATHOL',
  X_COORD: 141084.47247,
  Y_COORD: 926194.49069,
  OLD_X_LONG: -72.2177228,
  OLD_Y_LAT: 42.5840825,
  X_LONG: -72.21955268,
  Y_LAT: 42.63369448,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 16,
  TOWN: 'ATTLEBORO',
  X_COORD: 217016.96639,
  Y_COORD: 853433.89294,
  OLD_X_LONG: -71.2948231,
  OLD_Y_LAT: 41.9310715,
  X_LONG: -71.2966296,
  Y_LAT: 41.98068943,
  DX: -50,
  DY: -20
}, {
  TOWN_ID: 17,
  TOWN: 'AUBURN',
  X_COORD: 171486.34133,
  Y_COORD: 883040.91963,
  OLD_X_LONG: -71.8452385,
  OLD_Y_LAT: 42.1972957,
  X_LONG: -71.84705894,
  Y_LAT: 42.2469112,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 18,
  TOWN: 'AVON',
  X_COORD: 237268.29248,
  Y_COORD: 875229.67005,
  OLD_X_LONG: -71.0492634,
  OLD_Y_LAT: 42.1266031,
  X_LONG: -71.05106367,
  Y_LAT: 42.17621925,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 19,
  TOWN: 'AYER',
  X_COORD: 194109.93012,
  Y_COORD: 923582.40818,
  OLD_X_LONG: -71.5717291,
  OLD_Y_LAT: 42.5627769,
  X_LONG: -71.57354261,
  Y_LAT: 42.61238907,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 20,
  TOWN: 'BARNSTABLE',
  X_COORD: 294884.5898,
  Y_COORD: 825380.46516,
  OLD_X_LONG: -70.3605421,
  OLD_Y_LAT: 41.6729713,
  X_LONG: -70.36232492,
  Y_LAT: 41.72259158,
  DX: -20,
  DY: -50
}, {
  TOWN_ID: 21,
  TOWN: 'BARRE',
  X_COORD: 150120.83252,
  Y_COORD: 907915.0583,
  OLD_X_LONG: -72.1060615,
  OLD_Y_LAT: 42.4201587,
  X_LONG: -72.10788855,
  Y_LAT: 42.46977217,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 22,
  TOWN: 'BECKET',
  X_COORD: 69426.65207,
  Y_COORD: 893940.41659,
  OLD_X_LONG: -73.0832364,
  OLD_Y_LAT: 42.285033,
  X_LONG: -73.08508821,
  Y_LAT: 42.3346477,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 23,
  TOWN: 'BEDFORD',
  X_COORD: 218218.81603,
  Y_COORD: 916292.74219,
  OLD_X_LONG: -71.2783628,
  OLD_Y_LAT: 42.4969616,
  X_LONG: -71.28016888,
  Y_LAT: 42.54657437,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 24,
  TOWN: 'BELCHERTOWN',
  X_COORD: 125740.60423,
  Y_COORD: 892415.20708,
  OLD_X_LONG: -72.4002896,
  OLD_Y_LAT: 42.2786837,
  X_LONG: -72.40212411,
  Y_LAT: 42.32829846,
  DX: -10,
  DY: 125
}, {
  TOWN_ID: 25,
  TOWN: 'BELLINGHAM',
  X_COORD: 202150.47815,
  Y_COORD: 869700.51736,
  OLD_X_LONG: -71.4740115,
  OLD_Y_LAT: 42.0777065,
  X_LONG: -71.47582254,
  Y_LAT: 42.12732309,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 26,
  TOWN: 'BELMONT',
  X_COORD: 226369.37764,
  Y_COORD: 905123.84729,
  OLD_X_LONG: -71.1797199,
  OLD_Y_LAT: 42.3961799,
  X_LONG: -71.18152348,
  Y_LAT: 42.44579359,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 27,
  TOWN: 'BERKLEY',
  X_COORD: 235402.09589,
  Y_COORD: 842964.27498,
  OLD_X_LONG: -71.0737834,
  OLD_Y_LAT: 41.836197,
  X_LONG: -71.0755843,
  Y_LAT: 41.88581579,
  DX: -50,
  DY: -20
}, {
  TOWN_ID: 28,
  TOWN: 'BERLIN',
  X_COORD: 188837.1474,
  Y_COORD: 903709.58439,
  OLD_X_LONG: -71.6355563,
  OLD_Y_LAT: 42.3838136,
  X_LONG: -71.63737143,
  Y_LAT: 42.4334274,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 29,
  TOWN: 'BERNARDSTON',
  X_COORD: 114037.65084,
  Y_COORD: 938416.30129,
  OLD_X_LONG: -72.5490166,
  OLD_Y_LAT: 42.6915774,
  X_LONG: -72.55085488,
  Y_LAT: 42.7411884,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 30,
  TOWN: 'BEVERLY',
  X_COORD: 252373.06048,
  Y_COORD: 924441.74798,
  OLD_X_LONG: -70.8621356,
  OLD_Y_LAT: 42.5687723,
  X_LONG: -70.86393113,
  Y_LAT: 42.61838442,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 31,
  TOWN: 'BILLERICA',
  X_COORD: 219113.817,
  Y_COORD: 923152.79776,
  OLD_X_LONG: -71.2672472,
  OLD_Y_LAT: 42.5586969,
  X_LONG: -71.269053,
  Y_LAT: 42.60830911,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 32,
  TOWN: 'BLACKSTONE',
  X_COORD: 197424.9474,
  Y_COORD: 865503.36713,
  OLD_X_LONG: -71.531101,
  OLD_Y_LAT: 42.0399172,
  X_LONG: -71.53291348,
  Y_LAT: 42.08953414,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 33,
  TOWN: 'BLANDFORD',
  X_COORD: 80046.49811,
  Y_COORD: 882844.69305,
  OLD_X_LONG: -72.9522037,
  OLD_Y_LAT: 42.1868551,
  X_LONG: -72.95405219,
  Y_LAT: 42.2364707,
  DX: 10,
  DY: 100
}, {
  TOWN_ID: 34,
  TOWN: 'BOLTON',
  X_COORD: 191173.72913,
  Y_COORD: 909523.02057,
  OLD_X_LONG: -71.6072708,
  OLD_Y_LAT: 42.4361799,
  X_LONG: -71.60908521,
  Y_LAT: 42.48579322,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 35,
  TOWN: 'BOSTON',
  X_COORD: 232749.48475,
  Y_COORD: 895447.62438,
  OLD_X_LONG: -71.102776,
  OLD_Y_LAT: 42.308826,
  X_LONG: -71.10457763,
  Y_LAT: 42.35844048,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 36,
  TOWN: 'BOURNE',
  X_COORD: 276619.12734,
  Y_COORD: 829182.39253,
  OLD_X_LONG: -70.5793781,
  OLD_Y_LAT: 41.7091855,
  X_LONG: -70.58116647,
  Y_LAT: 41.75880545,
  DX: -20,
  DY: -50
}, {
  TOWN_ID: 37,
  TOWN: 'BOXBOROUGH',
  X_COORD: 198535.82654,
  Y_COORD: 915315.33583,
  OLD_X_LONG: -71.5178097,
  OLD_Y_LAT: 42.4883742,
  X_LONG: -71.51962185,
  Y_LAT: 42.53798705,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 38,
  TOWN: 'BOXFORD',
  X_COORD: 239422.91333,
  Y_COORD: 936882.64352,
  OLD_X_LONG: -71.0190003,
  OLD_Y_LAT: 42.6815293,
  X_LONG: -71.02079981,
  Y_LAT: 42.73114039,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 39,
  TOWN: 'BOYLSTON',
  X_COORD: 181773.27193,
  Y_COORD: 900669.02534,
  OLD_X_LONG: -71.7212404,
  OLD_Y_LAT: 42.3563072,
  X_LONG: -71.7230577,
  Y_LAT: 42.40592125,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 40,
  TOWN: 'BRAINTREE',
  X_COORD: 241080.48964,
  Y_COORD: 883804.72138,
  OLD_X_LONG: -71.0025534,
  OLD_Y_LAT: 42.2036133,
  X_LONG: -71.00435249,
  Y_LAT: 42.25322874,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 41,
  TOWN: 'BREWSTER',
  X_COORD: 319138.54849,
  Y_COORD: 833890.4078,
  OLD_X_LONG: -70.0676123,
  OLD_Y_LAT: 41.7462901,
  X_LONG: -70.0693877,
  Y_LAT: 41.79590971,
  DX: -20,
  DY: -50
}, {
  TOWN_ID: 42,
  TOWN: 'BRIDGEWATER',
  X_COORD: 243521.1966,
  Y_COORD: 858177.2568,
  OLD_X_LONG: -70.9749098,
  OLD_Y_LAT: 41.972756,
  X_LONG: -70.97670819,
  Y_LAT: 42.02237355,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 43,
  TOWN: 'BRIMFIELD',
  X_COORD: 141621.85254,
  Y_COORD: 875599.41558,
  OLD_X_LONG: -72.2060745,
  OLD_Y_LAT: 42.1286431,
  X_LONG: -72.20790409,
  Y_LAT: 42.17825923,
  DX: 10,
  DY: 100
}, {
  TOWN_ID: 44,
  TOWN: 'BROCKTON',
  X_COORD: 239370.60322,
  Y_COORD: 870292.61159,
  OLD_X_LONG: -71.0241709,
  OLD_Y_LAT: 42.0820515,
  X_LONG: -71.02597054,
  Y_LAT: 42.13166805,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 45,
  TOWN: 'BROOKFIELD',
  X_COORD: 150078.58378,
  Y_COORD: 882752.81372,
  OLD_X_LONG: -72.1044084,
  OLD_Y_LAT: 42.193629,
  X_LONG: -72.10623541,
  Y_LAT: 42.24324453,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 46,
  TOWN: 'BROOKLINE',
  X_COORD: 229615.29721,
  Y_COORD: 897144.5355,
  OLD_X_LONG: -71.1407039,
  OLD_Y_LAT: 42.3242281,
  X_LONG: -71.14250649,
  Y_LAT: 42.37384244,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 47,
  TOWN: 'BUCKLAND',
  X_COORD: 94289.43651,
  Y_COORD: 927592.94481,
  OLD_X_LONG: -72.7879806,
  OLD_Y_LAT: 42.5917181,
  X_LONG: -72.78982493,
  Y_LAT: 42.64133001,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 48,
  TOWN: 'BURLINGTON',
  X_COORD: 224436.7448,
  Y_COORD: 916896.55471,
  OLD_X_LONG: -71.2026948,
  OLD_Y_LAT: 42.5022271,
  X_LONG: -71.20449896,
  Y_LAT: 42.55183982,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 49,
  TOWN: 'CAMBRIDGE',
  X_COORD: 231429.81254,
  Y_COORD: 902873.63275,
  OLD_X_LONG: -71.1183793,
  OLD_Y_LAT: 42.3757346,
  X_LONG: -71.12018133,
  Y_LAT: 42.42534848,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 50,
  TOWN: 'CANTON',
  X_COORD: 230866.02025,
  Y_COORD: 880576.43437,
  OLD_X_LONG: -71.1264105,
  OLD_Y_LAT: 42.1750187,
  X_LONG: -71.12821273,
  Y_LAT: 42.2246344,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 51,
  TOWN: 'CARLISLE',
  X_COORD: 212218.19394,
  Y_COORD: 919955.36619,
  OLD_X_LONG: -71.3512843,
  OLD_Y_LAT: 42.5300513,
  X_LONG: -71.35309223,
  Y_LAT: 42.57966377,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 52,
  TOWN: 'CARVER',
  X_COORD: 261741.09552,
  Y_COORD: 847338.24234,
  OLD_X_LONG: -70.7562337,
  OLD_Y_LAT: 41.8739505,
  X_LONG: -70.75802655,
  Y_LAT: 41.92356895,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 53,
  TOWN: 'CHARLEMONT',
  X_COORD: 88102.98975,
  Y_COORD: 933095.91085,
  OLD_X_LONG: -72.8644164,
  OLD_Y_LAT: 42.6403849,
  X_LONG: -72.86626267,
  Y_LAT: 42.68999636,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 54,
  TOWN: 'CHARLTON',
  X_COORD: 161281.27934,
  Y_COORD: 876165.26963,
  OLD_X_LONG: -71.9683404,
  OLD_Y_LAT: 42.1349559,
  X_LONG: -71.97016396,
  Y_LAT: 42.18457197,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 55,
  TOWN: 'CHATHAM',
  X_COORD: 326006.92361,
  Y_COORD: 827394.95435,
  OLD_X_LONG: -69.9864372,
  OLD_Y_LAT: 41.686749,
  X_LONG: -69.98821054,
  Y_LAT: 41.73636915,
  DX: -20,
  DY: -50
}, {
  TOWN_ID: 56,
  TOWN: 'CHELMSFORD',
  X_COORD: 211235.77768,
  Y_COORD: 927719.18444,
  OLD_X_LONG: -71.3630902,
  OLD_Y_LAT: 42.5999584,
  X_LONG: -71.36489843,
  Y_LAT: 42.64957023,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 57,
  TOWN: 'CHELSEA',
  X_COORD: 238479.96254,
  Y_COORD: 905212.39567,
  OLD_X_LONG: -71.0326223,
  OLD_Y_LAT: 42.3964737,
  X_LONG: -71.03442215,
  Y_LAT: 42.44608739,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 58,
  TOWN: 'CHESHIRE',
  X_COORD: 64422.76872,
  Y_COORD: 925019.95781,
  OLD_X_LONG: -73.1511893,
  OLD_Y_LAT: 42.5639266,
  X_LONG: -73.15304283,
  Y_LAT: 42.61353876,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 59,
  TOWN: 'CHESTER',
  X_COORD: 81248.06172,
  Y_COORD: 894405.625,
  OLD_X_LONG: -72.9400225,
  OLD_Y_LAT: 42.2911066,
  X_LONG: -72.94187068,
  Y_LAT: 42.34072125,
  DX: 10,
  DY: 100
}, {
  TOWN_ID: 60,
  TOWN: 'CHESTERFIELD',
  X_COORD: 89293.99011,
  Y_COORD: 904910.3351,
  OLD_X_LONG: -72.8444796,
  OLD_Y_LAT: 42.3868484,
  X_LONG: -72.84632536,
  Y_LAT: 42.43646217,
  DX: -10,
  DY: 125
}, {
  TOWN_ID: 61,
  TOWN: 'CHICOPEE',
  X_COORD: 111446.01903,
  Y_COORD: 881213.61014,
  OLD_X_LONG: -72.5718679,
  OLD_Y_LAT: 42.1763544,
  X_LONG: -72.57370675,
  Y_LAT: 42.22597009,
  DX: 10,
  DY: 100
}, {
  TOWN_ID: 62,
  TOWN: 'CHILMARK',
  X_COORD: 264974.83864,
  Y_COORD: 789996.84614,
  OLD_X_LONG: -70.7235681,
  OLD_Y_LAT: 41.3574387,
  X_LONG: -70.72536012,
  Y_LAT: 41.40706185,
  DX: 50,
  DY: 20
}, {
  TOWN_ID: 63,
  TOWN: 'CLARKSBURG',
  X_COORD: 68236.72936,
  Y_COORD: 943032.78824,
  OLD_X_LONG: -73.1088879,
  OLD_Y_LAT: 42.7267062,
  X_LONG: -73.11074036,
  Y_LAT: 42.77631688,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 64,
  TOWN: 'CLINTON',
  X_COORD: 184455.72911,
  Y_COORD: 906842.85642,
  OLD_X_LONG: -71.6888462,
  OLD_Y_LAT: 42.4119464,
  X_LONG: -71.69066268,
  Y_LAT: 42.46155995,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 65,
  TOWN: 'COHASSET',
  X_COORD: 256093.38824,
  Y_COORD: 887442.36137,
  OLD_X_LONG: -70.8204169,
  OLD_Y_LAT: 42.2354298,
  X_LONG: -70.82221138,
  Y_LAT: 42.28504495,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 66,
  TOWN: 'COLRAIN',
  X_COORD: 100277.90538,
  Y_COORD: 937684.22186,
  OLD_X_LONG: -72.716781,
  OLD_Y_LAT: 42.6833427,
  X_LONG: -72.71862353,
  Y_LAT: 42.73295377,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 67,
  TOWN: 'CONCORD',
  X_COORD: 211202.15153,
  Y_COORD: 912395.94926,
  OLD_X_LONG: -71.3637981,
  OLD_Y_LAT: 42.4620132,
  X_LONG: -71.36560634,
  Y_LAT: 42.51162629,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 68,
  TOWN: 'CONWAY',
  X_COORD: 100856.83818,
  Y_COORD: 918214.78685,
  OLD_X_LONG: -72.7063583,
  OLD_Y_LAT: 42.5081661,
  X_LONG: -72.70820056,
  Y_LAT: 42.55777877,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 69,
  TOWN: 'CUMMINGTON',
  X_COORD: 83252.78117,
  Y_COORD: 913484.98023,
  OLD_X_LONG: -72.9195674,
  OLD_Y_LAT: 42.4631516,
  X_LONG: -72.92141506,
  Y_LAT: 42.51276468,
  DX: -10,
  DY: 125
}, {
  TOWN_ID: 70,
  TOWN: 'DALTON',
  X_COORD: 64063.62837,
  Y_COORD: 915645.12737,
  OLD_X_LONG: -73.1533493,
  OLD_Y_LAT: 42.4794836,
  X_LONG: -73.15520289,
  Y_LAT: 42.52909653,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 71,
  TOWN: 'DANVERS',
  X_COORD: 245110.57962,
  Y_COORD: 925070.93789,
  OLD_X_LONG: -70.9505352,
  OLD_Y_LAT: 42.5748913,
  X_LONG: -70.95233297,
  Y_LAT: 42.62450336,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 72,
  TOWN: 'DARTMOUTH',
  X_COORD: 241848.38133,
  Y_COORD: 818484.66411,
  OLD_X_LONG: -70.9979111,
  OLD_Y_LAT: 41.6154822,
  X_LONG: -70.99971007,
  Y_LAT: 41.665103,
  DX: -50,
  DY: -20
}, {
  TOWN_ID: 73,
  TOWN: 'DEDHAM',
  X_COORD: 226606.56797,
  Y_COORD: 888512.39744,
  OLD_X_LONG: -71.1776016,
  OLD_Y_LAT: 42.2466225,
  X_LONG: -71.17940513,
  Y_LAT: 42.29623755,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 74,
  TOWN: 'DEERFIELD',
  X_COORD: 108817.62499,
  Y_COORD: 919636.02262,
  OLD_X_LONG: -72.6097291,
  OLD_Y_LAT: 42.5219325,
  X_LONG: -72.61156891,
  Y_LAT: 42.57154504,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 75,
  TOWN: 'DENNIS',
  X_COORD: 311174.73151,
  Y_COORD: 829468.25831,
  OLD_X_LONG: -70.1641766,
  OLD_Y_LAT: 41.7076439,
  X_LONG: -70.16595445,
  Y_LAT: 41.75726386,
  DX: -20,
  DY: -50
}, {
  TOWN_ID: 76,
  TOWN: 'DIGHTON',
  X_COORD: 228595.12716,
  Y_COORD: 842938.93367,
  OLD_X_LONG: -71.1557348,
  OLD_Y_LAT: 41.8362456,
  X_LONG: -71.15753777,
  Y_LAT: 41.88586439,
  DX: -50,
  DY: -20
}, {
  TOWN_ID: 77,
  TOWN: 'DOUGLAS',
  X_COORD: 179222.86001,
  Y_COORD: 866905.47849,
  OLD_X_LONG: -71.7509918,
  OLD_Y_LAT: 42.0522698,
  X_LONG: -71.75280986,
  Y_LAT: 42.10188662,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 78,
  TOWN: 'DOVER',
  X_COORD: 217813.13475,
  Y_COORD: 887368.02703,
  OLD_X_LONG: -71.2841883,
  OLD_Y_LAT: 42.2365695,
  X_LONG: -71.28599453,
  Y_LAT: 42.28618464,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 79,
  TOWN: 'DRACUT',
  X_COORD: 216317.71019,
  Y_COORD: 936981.23825,
  OLD_X_LONG: -71.3009028,
  OLD_Y_LAT: 42.6832461,
  X_LONG: -71.30270945,
  Y_LAT: 42.73285717,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 80,
  TOWN: 'DUDLEY',
  X_COORD: 163970.56926,
  Y_COORD: 867272.53328,
  OLD_X_LONG: -71.9352625,
  OLD_Y_LAT: 42.0550221,
  X_LONG: -71.93708522,
  Y_LAT: 42.1046389,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 81,
  TOWN: 'DUNSTABLE',
  X_COORD: 199954.63224,
  Y_COORD: 936206.09165,
  OLD_X_LONG: -71.5005535,
  OLD_Y_LAT: 42.6764397,
  X_LONG: -71.50236521,
  Y_LAT: 42.72605084,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 82,
  TOWN: 'DUXBURY',
  X_COORD: 264471.71207,
  Y_COORD: 866509.07747,
  OLD_X_LONG: -70.7212325,
  OLD_Y_LAT: 42.0463263,
  X_LONG: -70.72302446,
  Y_LAT: 42.09594318,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 83,
  TOWN: 'EAST BRIDGEWATER',
  X_COORD: 246175.3515,
  Y_COORD: 865114.37999,
  OLD_X_LONG: -70.9423407,
  OLD_Y_LAT: 42.0350603,
  X_LONG: -70.94413827,
  Y_LAT: 42.08467728,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 84,
  TOWN: 'EAST BROOKFIELD',
  X_COORD: 154854.93469,
  Y_COORD: 883791.91106,
  OLD_X_LONG: -72.0466625,
  OLD_Y_LAT: 42.203274,
  X_LONG: -72.04848805,
  Y_LAT: 42.25288945,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 85,
  TOWN: 'EAST LONGMEADOW',
  X_COORD: 117307.59277,
  Y_COORD: 868187.05873,
  OLD_X_LONG: -72.4990786,
  OLD_Y_LAT: 42.0597232,
  X_LONG: -72.50091561,
  Y_LAT: 42.10933995,
  DX: 10,
  DY: 100
}, {
  TOWN_ID: 86,
  TOWN: 'EASTHAM',
  X_COORD: 326229.68797,
  Y_COORD: 844697.51623,
  OLD_X_LONG: -69.9800588,
  OLD_Y_LAT: 41.8424722,
  X_LONG: -69.98183198,
  Y_LAT: 41.89209093,
  DX: -20,
  DY: -50
}, {
  TOWN_ID: 87,
  TOWN: 'EASTHAMPTON',
  X_COORD: 103304.06913,
  Y_COORD: 891176.81335,
  OLD_X_LONG: -72.6720641,
  OLD_Y_LAT: 42.2650816,
  X_LONG: -72.67390549,
  Y_LAT: 42.31469648,
  DX: -10,
  DY: 125
}, {
  TOWN_ID: 88,
  TOWN: 'EASTON',
  X_COORD: 232266.13859,
  Y_COORD: 865159.56132,
  OLD_X_LONG: -71.1103168,
  OLD_Y_LAT: 42.0361625,
  X_LONG: -71.11211862,
  Y_LAT: 42.08577947,
  DX: -50,
  DY: -20
}, {
  TOWN_ID: 89,
  TOWN: 'EDGARTOWN',
  X_COORD: 280163.97931,
  Y_COORD: 792545.2706,
  OLD_X_LONG: -70.5417336,
  OLD_Y_LAT: 41.3789916,
  X_LONG: -70.54352101,
  Y_LAT: 41.42861456,
  DX: 50,
  DY: 20
}, {
  TOWN_ID: 90,
  TOWN: 'EGREMONT',
  X_COORD: 39767.72365,
  Y_COORD: 881802.63568,
  OLD_X_LONG: -73.4394004,
  OLD_Y_LAT: 42.1702659,
  X_LONG: -73.44126124,
  Y_LAT: 42.21988165,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 91,
  TOWN: 'ERVING',
  X_COORD: 123774.23276,
  Y_COORD: 928978.61592,
  OLD_X_LONG: -72.4289548,
  OLD_Y_LAT: 42.6076405,
  X_LONG: -72.43079003,
  Y_LAT: 42.65725226,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 92,
  TOWN: 'ESSEX',
  X_COORD: 259053.4416,
  Y_COORD: 931482.14636,
  OLD_X_LONG: -70.7800535,
  OLD_Y_LAT: 42.6316708,
  X_LONG: -70.78184695,
  Y_LAT: 42.68128234,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 93,
  TOWN: 'EVERETT',
  X_COORD: 236747.92214,
  Y_COORD: 906396.71832,
  OLD_X_LONG: -71.053584,
  OLD_Y_LAT: 42.4072192,
  X_LONG: -71.05538438,
  Y_LAT: 42.45683279,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 94,
  TOWN: 'FAIRHAVEN',
  X_COORD: 252343.04811,
  Y_COORD: 821222.42534,
  OLD_X_LONG: -70.8717603,
  OLD_Y_LAT: 41.6395051,
  X_LONG: -70.87355608,
  Y_LAT: 41.68912568,
  DX: -50,
  DY: -20
}, {
  TOWN_ID: 95,
  TOWN: 'FALL RIVER',
  X_COORD: 233361.64608,
  Y_COORD: 829314.88369,
  OLD_X_LONG: -71.099121,
  OLD_Y_LAT: 41.7133954,
  X_LONG: -71.10092254,
  Y_LAT: 41.76301531,
  DX: -50,
  DY: -20
}, {
  TOWN_ID: 96,
  TOWN: 'FALMOUTH',
  X_COORD: 275638.26072,
  Y_COORD: 816341.03921,
  OLD_X_LONG: -70.5928039,
  OLD_Y_LAT: 41.5936693,
  X_LONG: -70.59459261,
  Y_LAT: 41.6432903,
  DX: -20,
  DY: -50
}, {
  TOWN_ID: 97,
  TOWN: 'FITCHBURG',
  X_COORD: 174096.04654,
  Y_COORD: 926784.69703,
  OLD_X_LONG: -71.8156006,
  OLD_Y_LAT: 42.5911957,
  X_LONG: -71.81742029,
  Y_LAT: 42.64080761,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 98,
  TOWN: 'FLORIDA',
  X_COORD: 75875.67765,
  Y_COORD: 937741.90835,
  OLD_X_LONG: -73.0144862,
  OLD_Y_LAT: 42.680345,
  X_LONG: -73.01633627,
  Y_LAT: 42.7299561,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 99,
  TOWN: 'FOXBOROUGH',
  X_COORD: 221015.12674,
  Y_COORD: 868065.7286,
  OLD_X_LONG: -71.2460915,
  OLD_Y_LAT: 42.0627095,
  X_LONG: -71.24789676,
  Y_LAT: 42.11232623,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 100,
  TOWN: 'FRAMINGHAM',
  X_COORD: 205215.82089,
  Y_COORD: 895342.25907,
  OLD_X_LONG: -71.4367369,
  OLD_Y_LAT: 42.3085466,
  X_LONG: -71.43854699,
  Y_LAT: 42.35816109,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 101,
  TOWN: 'FRANKLIN',
  X_COORD: 207346.59002,
  Y_COORD: 870653.54334,
  OLD_X_LONG: -71.4112044,
  OLD_Y_LAT: 42.0862554,
  X_LONG: -71.41301385,
  Y_LAT: 42.13587191,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 102,
  TOWN: 'FREETOWN',
  X_COORD: 240443.67404,
  Y_COORD: 835762.03435,
  OLD_X_LONG: -71.013582,
  OLD_Y_LAT: 41.7711099,
  X_LONG: -71.01538137,
  Y_LAT: 41.82072928,
  DX: -50,
  DY: -20
}, {
  TOWN_ID: 103,
  TOWN: 'GARDNER',
  X_COORD: 160046.59029,
  Y_COORD: 926110.85275,
  OLD_X_LONG: -71.9867222,
  OLD_Y_LAT: 42.5845348,
  X_LONG: -71.98854623,
  Y_LAT: 42.63414677,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 104,
  TOWN: 'AQUINNAH',
  X_COORD: 258203.1613,
  Y_COORD: 787749.05449,
  OLD_X_LONG: -70.804703,
  OLD_Y_LAT: 41.3377277,
  X_LONG: -70.80649708,
  Y_LAT: 41.38735103,
  DX: 50,
  DY: 20
}, {
  TOWN_ID: 105,
  TOWN: 'GEORGETOWN',
  X_COORD: 242415.86156,
  Y_COORD: 941619.38887,
  OLD_X_LONG: -70.9821333,
  OLD_Y_LAT: 42.7240107,
  X_LONG: -70.98393187,
  Y_LAT: 42.7736214,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 106,
  TOWN: 'GILL',
  X_COORD: 117071.13096,
  Y_COORD: 931948.04613,
  OLD_X_LONG: -72.5110663,
  OLD_Y_LAT: 42.6336838,
  X_LONG: -72.51290361,
  Y_LAT: 42.68329532,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 107,
  TOWN: 'GLOUCESTER',
  X_COORD: 269171.73898,
  Y_COORD: 931974.1226,
  OLD_X_LONG: -70.6566451,
  OLD_Y_LAT: 42.6352648,
  X_LONG: -70.65843543,
  Y_LAT: 42.68487631,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 108,
  TOWN: 'GOSHEN',
  X_COORD: 92520.94248,
  Y_COORD: 911047.39811,
  OLD_X_LONG: -72.8064372,
  OLD_Y_LAT: 42.4425431,
  X_LONG: -72.808282,
  Y_LAT: 42.49215637,
  DX: -10,
  DY: 125
}, {
  TOWN_ID: 109,
  TOWN: 'GOSNOLD',
  X_COORD: 262051.27632,
  Y_COORD: 803543.38241,
  OLD_X_LONG: -70.7570922,
  OLD_Y_LAT: 41.4796341,
  X_LONG: -70.75888507,
  Y_LAT: 41.52925614,
  DX: 50,
  DY: 20
}, {
  TOWN_ID: 110,
  TOWN: 'GRAFTON',
  X_COORD: 184829.32265,
  Y_COORD: 884252.48863,
  OLD_X_LONG: -71.6837163,
  OLD_Y_LAT: 42.208576,
  X_LONG: -71.68553265,
  Y_LAT: 42.2581914,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 111,
  TOWN: 'GRANBY',
  X_COORD: 117199.32815,
  Y_COORD: 890523.72167,
  OLD_X_LONG: -72.5035616,
  OLD_Y_LAT: 42.2607977,
  X_LONG: -72.50539872,
  Y_LAT: 42.31041262,
  DX: -10,
  DY: 125
}, {
  TOWN_ID: 112,
  TOWN: 'GRANVILLE',
  X_COORD: 83461.7602,
  Y_COORD: 871102.33572,
  OLD_X_LONG: -72.9085175,
  OLD_Y_LAT: 42.0816671,
  X_LONG: -72.91036488,
  Y_LAT: 42.13128365,
  DX: 10,
  DY: 100
}, {
  TOWN_ID: 113,
  TOWN: 'GREAT BARRINGTON',
  X_COORD: 47949.01536,
  Y_COORD: 886146.14145,
  OLD_X_LONG: -73.3415431,
  OLD_Y_LAT: 42.2109936,
  X_LONG: -73.34340146,
  Y_LAT: 42.26060898,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 114,
  TOWN: 'GREENFIELD',
  X_COORD: 109867.84079,
  Y_COORD: 929851.42108,
  OLD_X_LONG: -72.5985501,
  OLD_Y_LAT: 42.6140093,
  X_LONG: -72.60038963,
  Y_LAT: 42.663621,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 115,
  TOWN: 'GROTON',
  X_COORD: 194969.07891,
  Y_COORD: 929239.64848,
  OLD_X_LONG: -71.561316,
  OLD_Y_LAT: 42.6137108,
  X_LONG: -71.56312925,
  Y_LAT: 42.66332251,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 116,
  TOWN: 'GROVELAND',
  X_COORD: 239713.83036,
  Y_COORD: 944713.03852,
  OLD_X_LONG: -71.0149076,
  OLD_Y_LAT: 42.7520017,
  X_LONG: -71.016707,
  Y_LAT: 42.80161215,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 117,
  TOWN: 'HADLEY',
  X_COORD: 111908.82511,
  Y_COORD: 901109.51738,
  OLD_X_LONG: -72.5692854,
  OLD_Y_LAT: 42.3555146,
  X_LONG: -72.57112419,
  Y_LAT: 42.40512866,
  DX: -10,
  DY: 125
}, {
  TOWN_ID: 118,
  TOWN: 'HALIFAX',
  X_COORD: 252766.34311,
  Y_COORD: 860309.46006,
  OLD_X_LONG: -70.8631774,
  OLD_Y_LAT: 41.9913856,
  X_LONG: -70.86497296,
  Y_LAT: 42.04100298,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 119,
  TOWN: 'HAMILTON',
  X_COORD: 252673.2334,
  Y_COORD: 930872.74603,
  OLD_X_LONG: -70.8578903,
  OLD_Y_LAT: 42.6266437,
  X_LONG: -70.85968573,
  Y_LAT: 42.67625529,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 120,
  TOWN: 'HAMPDEN',
  X_COORD: 124215.71082,
  Y_COORD: 868568.10504,
  OLD_X_LONG: -72.4156718,
  OLD_Y_LAT: 42.0638517,
  X_LONG: -72.4175067,
  Y_LAT: 42.11346842,
  DX: 10,
  DY: 100
}, {
  TOWN_ID: 121,
  TOWN: 'HANCOCK',
  X_COORD: 50501.41845,
  Y_COORD: 921675.6653,
  OLD_X_LONG: -73.3198189,
  OLD_Y_LAT: 42.5312765,
  X_LONG: -73.32167671,
  Y_LAT: 42.58088896,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 122,
  TOWN: 'HANOVER',
  X_COORD: 253204.71834,
  Y_COORD: 874866.01904,
  OLD_X_LONG: -70.8565617,
  OLD_Y_LAT: 42.1224088,
  X_LONG: -70.85835709,
  Y_LAT: 42.17202498,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 123,
  TOWN: 'HANSON',
  X_COORD: 251955.6791,
  Y_COORD: 867456.23353,
  OLD_X_LONG: -70.872326,
  OLD_Y_LAT: 42.0557825,
  X_LONG: -70.87412179,
  Y_LAT: 42.10539929,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 124,
  TOWN: 'HARDWICK',
  X_COORD: 141093.14094,
  Y_COORD: 900724.89073,
  OLD_X_LONG: -72.2150163,
  OLD_Y_LAT: 42.3548001,
  X_LONG: -72.21684611,
  Y_LAT: 42.40441417,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 125,
  TOWN: 'HARVARD',
  X_COORD: 192988.36693,
  Y_COORD: 917263.59016,
  OLD_X_LONG: -71.5853105,
  OLD_Y_LAT: 42.5058831,
  X_LONG: -71.58712436,
  Y_LAT: 42.55549579,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 126,
  TOWN: 'HARWICH',
  X_COORD: 319116.73492,
  Y_COORD: 828268.85303,
  OLD_X_LONG: -70.0690083,
  OLD_Y_LAT: 41.6956868,
  X_LONG: -70.07078374,
  Y_LAT: 41.74530687,
  DX: -20,
  DY: -50
}, {
  TOWN_ID: 127,
  TOWN: 'HATFIELD',
  X_COORD: 107634.60306,
  Y_COORD: 904761.26013,
  OLD_X_LONG: -72.6217447,
  OLD_Y_LAT: 42.3878934,
  X_LONG: -72.62358482,
  Y_LAT: 42.43750716,
  DX: -10,
  DY: 125
}, {
  TOWN_ID: 128,
  TOWN: 'HAVERHILL',
  X_COORD: 233787.37628,
  Y_COORD: 948222.56028,
  OLD_X_LONG: -71.0870887,
  OLD_Y_LAT: 42.7838739,
  X_LONG: -71.08888993,
  Y_LAT: 42.83348406,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 129,
  TOWN: 'HAWLEY',
  X_COORD: 84556.12935,
  Y_COORD: 927023.75873,
  OLD_X_LONG: -72.9064366,
  OLD_Y_LAT: 42.5852109,
  X_LONG: -72.90828393,
  Y_LAT: 42.63482287,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 130,
  TOWN: 'HEATH',
  X_COORD: 91806.09145,
  Y_COORD: 938500.84716,
  OLD_X_LONG: -72.82029,
  OLD_Y_LAT: 42.689559,
  X_LONG: -72.82213515,
  Y_LAT: 42.73917002,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 131,
  TOWN: 'HINGHAM',
  X_COORD: 250786.09828,
  Y_COORD: 885240.71134,
  OLD_X_LONG: -70.8849058,
  OLD_Y_LAT: 42.2159715,
  X_LONG: -70.88670191,
  Y_LAT: 42.26558683,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 132,
  TOWN: 'HINSDALE',
  X_COORD: 67255.29998,
  Y_COORD: 910624.64232,
  OLD_X_LONG: -73.1133845,
  OLD_Y_LAT: 42.4348448,
  X_LONG: -73.11523708,
  Y_LAT: 42.48445814,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 133,
  TOWN: 'HOLBROOK',
  X_COORD: 240860.91541,
  Y_COORD: 877523.49871,
  OLD_X_LONG: -71.005653,
  OLD_Y_LAT: 42.1470753,
  X_LONG: -71.00745217,
  Y_LAT: 42.19669126,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 134,
  TOWN: 'HOLDEN',
  X_COORD: 170282.16611,
  Y_COORD: 900687.09549,
  OLD_X_LONG: -71.8607218,
  OLD_Y_LAT: 42.3561169,
  X_LONG: -71.86254264,
  Y_LAT: 42.40573095,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 135,
  TOWN: 'HOLLAND',
  X_COORD: 144883.67456,
  Y_COORD: 867965.48733,
  OLD_X_LONG: -72.1659041,
  OLD_Y_LAT: 42.0601515,
  X_LONG: -72.16773267,
  Y_LAT: 42.10976825,
  DX: 10,
  DY: 100
}, {
  TOWN_ID: 136,
  TOWN: 'HOLLISTON',
  X_COORD: 204542.73583,
  Y_COORD: 883033.2241,
  OLD_X_LONG: -71.4449971,
  OLD_Y_LAT: 42.1977328,
  X_LONG: -71.4468074,
  Y_LAT: 42.2473483,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 137,
  TOWN: 'HOLYOKE',
  X_COORD: 105781.27366,
  Y_COORD: 885306.0883,
  OLD_X_LONG: -72.6410893,
  OLD_Y_LAT: 42.2125346,
  X_LONG: -72.64292991,
  Y_LAT: 42.26214996,
  DX: 10,
  DY: 100
}, {
  TOWN_ID: 138,
  TOWN: 'HOPEDALE',
  X_COORD: 197097.81743,
  Y_COORD: 874942.0678,
  OLD_X_LONG: -71.535099,
  OLD_Y_LAT: 42.1248949,
  X_LONG: -71.53691158,
  Y_LAT: 42.17451106,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 139,
  TOWN: 'HOPKINTON',
  X_COORD: 196879.72532,
  Y_COORD: 886114.94193,
  OLD_X_LONG: -71.5377964,
  OLD_Y_LAT: 42.2254846,
  X_LONG: -71.53960905,
  Y_LAT: 42.27509984,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 140,
  TOWN: 'HUBBARDSTON',
  X_COORD: 158473.94816,
  Y_COORD: 914922.59232,
  OLD_X_LONG: -72.005073,
  OLD_Y_LAT: 42.4837331,
  X_LONG: -72.00689749,
  Y_LAT: 42.53334599,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 141,
  TOWN: 'HUDSON',
  X_COORD: 196172.55522,
  Y_COORD: 904234.4327,
  OLD_X_LONG: -71.5464821,
  OLD_Y_LAT: 42.3886091,
  X_LONG: -71.54829497,
  Y_LAT: 42.43822286,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 142,
  TOWN: 'HULL',
  X_COORD: 251743.75251,
  Y_COORD: 892655.95955,
  OLD_X_LONG: -70.8726471,
  OLD_Y_LAT: 42.2826663,
  X_LONG: -70.8744429,
  Y_LAT: 42.33228102,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 143,
  TOWN: 'HUNTINGTON',
  X_COORD: 88255.38929,
  Y_COORD: 893538.09913,
  OLD_X_LONG: -72.8548972,
  OLD_Y_LAT: 42.2843309,
  X_LONG: -72.85674323,
  Y_LAT: 42.33394561,
  DX: -10,
  DY: 125
}, {
  TOWN_ID: 144,
  TOWN: 'IPSWICH',
  X_COORD: 253329.24786,
  Y_COORD: 936917.58255,
  OLD_X_LONG: -70.8493313,
  OLD_Y_LAT: 42.6810134,
  X_LONG: -70.85112651,
  Y_LAT: 42.73062449,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 145,
  TOWN: 'KINGSTON',
  X_COORD: 262283.20946,
  Y_COORD: 859807.39541,
  OLD_X_LONG: -70.7483798,
  OLD_Y_LAT: 41.9861681,
  X_LONG: -70.75017245,
  Y_LAT: 42.03578552,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 146,
  TOWN: 'LAKEVILLE',
  X_COORD: 244904.14395,
  Y_COORD: 842442.3544,
  OLD_X_LONG: -70.9594279,
  OLD_Y_LAT: 41.8310131,
  X_LONG: -70.9612259,
  Y_LAT: 41.88063194,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 147,
  TOWN: 'LANCASTER',
  X_COORD: 185129.02685,
  Y_COORD: 914596.97637,
  OLD_X_LONG: -71.6808661,
  OLD_Y_LAT: 42.4817666,
  X_LONG: -71.68268238,
  Y_LAT: 42.53137951,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 148,
  TOWN: 'LANESBOROUGH',
  X_COORD: 57113.28043,
  Y_COORD: 921592.87133,
  OLD_X_LONG: -73.2393362,
  OLD_Y_LAT: 42.5317731,
  X_LONG: -73.24119197,
  Y_LAT: 42.58138555,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 149,
  TOWN: 'LAWRENCE',
  X_COORD: 227650.35156,
  Y_COORD: 938908.04255,
  OLD_X_LONG: -71.162538,
  OLD_Y_LAT: 42.7002702,
  X_LONG: -71.16434114,
  Y_LAT: 42.74988112,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 150,
  TOWN: 'LEE',
  X_COORD: 57022.04455,
  Y_COORD: 896064.15322,
  OLD_X_LONG: -73.2341294,
  OLD_Y_LAT: 42.3019777,
  X_LONG: -73.23598504,
  Y_LAT: 42.35159225,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 151,
  TOWN: 'LEICESTER',
  X_COORD: 165994.62713,
  Y_COORD: 887810.36865,
  OLD_X_LONG: -71.9120092,
  OLD_Y_LAT: 42.2400154,
  X_LONG: -71.91383134,
  Y_LAT: 42.28963051,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 152,
  TOWN: 'LENOX',
  X_COORD: 53735.59674,
  Y_COORD: 903870.37284,
  OLD_X_LONG: -73.2759492,
  OLD_Y_LAT: 42.3716321,
  X_LONG: -73.2778059,
  Y_LAT: 42.42124601,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 153,
  TOWN: 'LEOMINSTER',
  X_COORD: 177674.23487,
  Y_COORD: 918957.58747,
  OLD_X_LONG: -71.7717024,
  OLD_Y_LAT: 42.5208447,
  X_LONG: -71.77352098,
  Y_LAT: 42.57045725,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 154,
  TOWN: 'LEVERETT',
  X_COORD: 118806.30683,
  Y_COORD: 914145.53747,
  OLD_X_LONG: -72.4874005,
  OLD_Y_LAT: 42.4736136,
  X_LONG: -72.48923721,
  Y_LAT: 42.52322658,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 155,
  TOWN: 'LEXINGTON',
  X_COORD: 222151.91614,
  Y_COORD: 910600.70682,
  OLD_X_LONG: -71.2307344,
  OLD_Y_LAT: 42.4456171,
  X_LONG: -71.23253927,
  Y_LAT: 42.49523034,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 156,
  TOWN: 'LEYDEN',
  X_COORD: 107698.28544,
  Y_COORD: 938978.80927,
  OLD_X_LONG: -72.6264591,
  OLD_Y_LAT: 42.6959129,
  X_LONG: -72.62829934,
  Y_LAT: 42.74552386,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 157,
  TOWN: 'LINCOLN',
  X_COORD: 215746.76653,
  Y_COORD: 908473.05492,
  OLD_X_LONG: -71.3086493,
  OLD_Y_LAT: 42.4266184,
  X_LONG: -71.31045615,
  Y_LAT: 42.47623181,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 158,
  TOWN: 'LITTLETON',
  X_COORD: 200896.3881,
  Y_COORD: 920494.67849,
  OLD_X_LONG: -71.4890886,
  OLD_Y_LAT: 42.5350018,
  X_LONG: -71.49090002,
  Y_LAT: 42.58461422,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 159,
  TOWN: 'LONGMEADOW',
  X_COORD: 111263.38984,
  Y_COORD: 866911.25356,
  OLD_X_LONG: -72.5719028,
  OLD_Y_LAT: 42.047577,
  X_LONG: -72.57374166,
  Y_LAT: 42.09719387,
  DX: 10,
  DY: 100
}, {
  TOWN_ID: 160,
  TOWN: 'LOWELL',
  X_COORD: 214619.15105,
  Y_COORD: 932049.50822,
  OLD_X_LONG: -71.3217531,
  OLD_Y_LAT: 42.6388843,
  X_LONG: -71.32356028,
  Y_LAT: 42.68849578,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 161,
  TOWN: 'LUDLOW',
  X_COORD: 120873.69303,
  Y_COORD: 882843.87513,
  OLD_X_LONG: -72.457986,
  OLD_Y_LAT: 42.1920407,
  X_LONG: -72.45982197,
  Y_LAT: 42.24165625,
  DX: 10,
  DY: 100
}, {
  TOWN_ID: 162,
  TOWN: 'LUNENBURG',
  X_COORD: 181957.74702,
  Y_COORD: 926589.00507,
  OLD_X_LONG: -71.719812,
  OLD_Y_LAT: 42.5896561,
  X_LONG: -71.72162927,
  Y_LAT: 42.63926803,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 163,
  TOWN: 'LYNN',
  X_COORD: 243886.9884,
  Y_COORD: 914344.84586,
  OLD_X_LONG: -70.9662561,
  OLD_Y_LAT: 42.4784026,
  X_LONG: -70.96805427,
  Y_LAT: 42.52801554,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 164,
  TOWN: 'LYNNFIELD',
  X_COORD: 237938.38581,
  Y_COORD: 920667.37609,
  OLD_X_LONG: -71.0381839,
  OLD_Y_LAT: 42.5356324,
  X_LONG: -71.03998389,
  Y_LAT: 42.58524482,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 165,
  TOWN: 'MALDEN',
  X_COORD: 236407.20049,
  Y_COORD: 908983.4819,
  OLD_X_LONG: -71.0575601,
  OLD_Y_LAT: 42.4305226,
  X_LONG: -71.05936058,
  Y_LAT: 42.48013598,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 166,
  TOWN: 'MANCHESTER',
  X_COORD: 259992.05468,
  Y_COORD: 926029.11566,
  OLD_X_LONG: -70.7691806,
  OLD_Y_LAT: 42.5825115,
  X_LONG: -70.77097378,
  Y_LAT: 42.63212349,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 167,
  TOWN: 'MANSFIELD',
  X_COORD: 223301.16931,
  Y_COORD: 862916.40777,
  OLD_X_LONG: -71.2186766,
  OLD_Y_LAT: 42.0162843,
  X_LONG: -71.22048117,
  Y_LAT: 42.06590145,
  DX: -50,
  DY: -20
}, {
  TOWN_ID: 168,
  TOWN: 'MARBLEHEAD',
  X_COORD: 252292.52068,
  Y_COORD: 916705.00352,
  OLD_X_LONG: -70.8638193,
  OLD_Y_LAT: 42.4991305,
  X_LONG: -70.86561488,
  Y_LAT: 42.54874325,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 169,
  TOWN: 'MARION',
  X_COORD: 261177.15571,
  Y_COORD: 829051.60538,
  OLD_X_LONG: -70.7649253,
  OLD_Y_LAT: 41.7093573,
  X_LONG: -70.76671837,
  Y_LAT: 41.75897725,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 170,
  TOWN: 'MARLBOROUGH',
  X_COORD: 196131.61953,
  Y_COORD: 899876.89442,
  OLD_X_LONG: -71.5469501,
  OLD_Y_LAT: 42.349379,
  X_LONG: -71.54876299,
  Y_LAT: 42.39899312,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 171,
  TOWN: 'MARSHFIELD',
  X_COORD: 264883.72433,
  Y_COORD: 873968.39479,
  OLD_X_LONG: -70.7154272,
  OLD_Y_LAT: 42.1134475,
  X_LONG: -70.71721902,
  Y_LAT: 42.16306377,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 172,
  TOWN: 'MASHPEE',
  X_COORD: 284092.53846,
  Y_COORD: 819364.40715,
  OLD_X_LONG: -70.4909843,
  OLD_Y_LAT: 41.6200336,
  X_LONG: -70.49277043,
  Y_LAT: 41.66965436,
  DX: -20,
  DY: -50
}, {
  TOWN_ID: 173,
  TOWN: 'MATTAPOISETT',
  X_COORD: 256850.40788,
  Y_COORD: 824580.27872,
  OLD_X_LONG: -70.817341,
  OLD_Y_LAT: 41.6694248,
  X_LONG: -70.8191354,
  Y_LAT: 41.71904511,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 174,
  TOWN: 'MAYNARD',
  X_COORD: 203621.98032,
  Y_COORD: 908439.79677,
  OLD_X_LONG: -71.4559868,
  OLD_Y_LAT: 42.4264696,
  X_LONG: -71.45779738,
  Y_LAT: 42.47608301,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 175,
  TOWN: 'MEDFIELD',
  X_COORD: 216109.39,
  Y_COORD: 881619.17506,
  OLD_X_LONG: -71.3049889,
  OLD_Y_LAT: 42.1848494,
  X_LONG: -71.30679565,
  Y_LAT: 42.23446501,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 176,
  TOWN: 'MEDFORD',
  X_COORD: 232170.27249,
  Y_COORD: 908222.95468,
  OLD_X_LONG: -71.1090911,
  OLD_Y_LAT: 42.4238622,
  X_LONG: -71.11089289,
  Y_LAT: 42.47347564,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 177,
  TOWN: 'MEDWAY',
  X_COORD: 205864.57268,
  Y_COORD: 878122.52869,
  OLD_X_LONG: -71.4290419,
  OLD_Y_LAT: 42.1535125,
  X_LONG: -71.4308518,
  Y_LAT: 42.2031284,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 179,
  TOWN: 'MELROSE',
  X_COORD: 236275.04843,
  Y_COORD: 911772.36307,
  OLD_X_LONG: -71.0589908,
  OLD_Y_LAT: 42.4556356,
  X_LONG: -71.06079132,
  Y_LAT: 42.50524875,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 179,
  TOWN: 'MENDON',
  X_COORD: 195747.10339,
  Y_COORD: 871402.66809,
  OLD_X_LONG: -71.5514087,
  OLD_Y_LAT: 42.0930228,
  X_LONG: -71.5532217,
  Y_LAT: 42.14263925,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 180,
  TOWN: 'MERRIMAC',
  X_COORD: 239896.06169,
  Y_COORD: 954346.2147,
  OLD_X_LONG: -71.0120085,
  OLD_Y_LAT: 42.8387054,
  X_LONG: -71.01380783,
  Y_LAT: 42.88831506,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 181,
  TOWN: 'METHUEN',
  X_COORD: 225485.1883,
  Y_COORD: 942648.43527,
  OLD_X_LONG: -71.1887962,
  OLD_Y_LAT: 42.7340146,
  X_LONG: -71.19060001,
  Y_LAT: 42.78362521,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 182,
  TOWN: 'MIDDLEBOROUGH',
  X_COORD: 251934.84378,
  Y_COORD: 847967.03433,
  OLD_X_LONG: -70.8743048,
  OLD_Y_LAT: 41.8803204,
  X_LONG: -70.87610064,
  Y_LAT: 41.92993879,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 183,
  TOWN: 'MIDDLEFIELD',
  X_COORD: 75130.1574,
  Y_COORD: 901214.73025,
  OLD_X_LONG: -73.015662,
  OLD_Y_LAT: 42.3514446,
  X_LONG: -73.0175121,
  Y_LAT: 42.4010587,
  DX: -10,
  DY: 125
}, {
  TOWN_ID: 184,
  TOWN: 'MIDDLETON',
  X_COORD: 239682.68628,
  Y_COORD: 928306.11738,
  OLD_X_LONG: -71.0164241,
  OLD_Y_LAT: 42.6043106,
  X_LONG: -71.01822354,
  Y_LAT: 42.65392239,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 185,
  TOWN: 'MILFORD',
  X_COORD: 198451.25273,
  Y_COORD: 878449.312,
  OLD_X_LONG: -71.5187399,
  OLD_Y_LAT: 42.156475,
  X_LONG: -71.52055207,
  Y_LAT: 42.20609087,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 186,
  TOWN: 'MILLBURY',
  X_COORD: 177360.20069,
  Y_COORD: 882483.18611,
  OLD_X_LONG: -71.7740977,
  OLD_Y_LAT: 42.1924664,
  X_LONG: -71.77591634,
  Y_LAT: 42.24208195,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 187,
  TOWN: 'MILLIS',
  X_COORD: 211358.16491,
  Y_COORD: 879881.8656,
  OLD_X_LONG: -71.3625383,
  OLD_Y_LAT: 42.1692916,
  X_LONG: -71.36434651,
  Y_LAT: 42.21890736,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 188,
  TOWN: 'MILLVILLE',
  X_COORD: 193579.24201,
  Y_COORD: 865390.9511,
  OLD_X_LONG: -71.5775476,
  OLD_Y_LAT: 42.038883,
  X_LONG: -71.57936126,
  Y_LAT: 42.08849994,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 189,
  TOWN: 'MILTON',
  X_COORD: 234285.1359,
  Y_COORD: 887919.30858,
  OLD_X_LONG: -71.0845948,
  OLD_Y_LAT: 42.2409839,
  X_LONG: -71.08639597,
  Y_LAT: 42.290599,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 190,
  TOWN: 'MONROE',
  X_COORD: 78576.09593,
  Y_COORD: 942395.12468,
  OLD_X_LONG: -72.9825308,
  OLD_Y_LAT: 42.7226536,
  X_LONG: -72.98438006,
  Y_LAT: 42.77226431,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 191,
  TOWN: 'MONSON',
  X_COORD: 131667.05263,
  Y_COORD: 871771.40883,
  OLD_X_LONG: -72.3260209,
  OLD_Y_LAT: 42.0933752,
  X_LONG: -72.32785353,
  Y_LAT: 42.14299165,
  DX: 10,
  DY: 100
}, {
  TOWN_ID: 192,
  TOWN: 'MONTAGUE',
  X_COORD: 116420.57376,
  Y_COORD: 923204.67277,
  OLD_X_LONG: -72.5177253,
  OLD_Y_LAT: 42.5549099,
  X_LONG: -72.51956278,
  Y_LAT: 42.60452214,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 193,
  TOWN: 'MONTEREY',
  X_COORD: 57332.61249,
  Y_COORD: 883678.47448,
  OLD_X_LONG: -73.227322,
  OLD_Y_LAT: 42.1905492,
  X_LONG: -73.22917746,
  Y_LAT: 42.24016476,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 194,
  TOWN: 'MONTGOMERY',
  X_COORD: 90980.32054,
  Y_COORD: 885134.40045,
  OLD_X_LONG: -72.8202858,
  OLD_Y_LAT: 42.2090665,
  X_LONG: -72.82213095,
  Y_LAT: 42.25868189,
  DX: 10,
  DY: 100
}, {
  TOWN_ID: 195,
  TOWN: 'MOUNT WASHINGTON',
  X_COORD: 37357.81579,
  Y_COORD: 873382.26112,
  OLD_X_LONG: -73.4662099,
  OLD_Y_LAT: 42.093979,
  X_LONG: -73.46807142,
  Y_LAT: 42.14359544,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 196,
  TOWN: 'NAHANT',
  X_COORD: 247302.43426,
  Y_COORD: 908710.14319,
  OLD_X_LONG: -70.9251812,
  OLD_Y_LAT: 42.427477,
  X_LONG: -70.92697833,
  Y_LAT: 42.4770904,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 197,
  TOWN: 'NANTUCKET',
  X_COORD: 320239.64504,
  Y_COORD: 781715.56401,
  OLD_X_LONG: -70.0649294,
  OLD_Y_LAT: 41.2764346,
  X_LONG: -70.06670473,
  Y_LAT: 41.32605849,
  DX: -10,
  DY: -75
}, {
  TOWN_ID: 198,
  TOWN: 'NATICK',
  X_COORD: 212399.96957,
  Y_COORD: 892706.47625,
  OLD_X_LONG: -71.3496563,
  OLD_Y_LAT: 42.2847359,
  X_LONG: -71.35146419,
  Y_LAT: 42.3343506,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 199,
  TOWN: 'NEEDHAM',
  X_COORD: 221345.60508,
  Y_COORD: 892348.44313,
  OLD_X_LONG: -71.2412085,
  OLD_Y_LAT: 42.2813194,
  X_LONG: -71.24301364,
  Y_LAT: 42.33093414,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 200,
  TOWN: 'NEW ASHFORD',
  X_COORD: 58545.36971,
  Y_COORD: 930109.65372,
  OLD_X_LONG: -73.2240035,
  OLD_Y_LAT: 42.6086896,
  X_LONG: -73.22585888,
  Y_LAT: 42.65830135,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 201,
  TOWN: 'NEW BEDFORD',
  X_COORD: 246289.70167,
  Y_COORD: 824717.19539,
  OLD_X_LONG: -70.9441391,
  OLD_Y_LAT: 41.6713478,
  X_LONG: -70.94593671,
  Y_LAT: 41.72096809,
  DX: -50,
  DY: -20
}, {
  TOWN_ID: 202,
  TOWN: 'NEW BRAINTREE',
  X_COORD: 148236.33382,
  Y_COORD: 896723.60015,
  OLD_X_LONG: -72.1279574,
  OLD_Y_LAT: 42.3192846,
  X_LONG: -72.12978501,
  Y_LAT: 42.36889899,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 203,
  TOWN: 'NEW MARLBOROUGH',
  X_COORD: 56088.0168,
  Y_COORD: 873739.33166,
  OLD_X_LONG: -73.2399322,
  OLD_Y_LAT: 42.1008563,
  X_LONG: -73.24178798,
  Y_LAT: 42.15047268,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 204,
  TOWN: 'NEW SALEM',
  X_COORD: 131994.74721,
  Y_COORD: 911650.85962,
  OLD_X_LONG: -72.3267322,
  OLD_Y_LAT: 42.452419,
  X_LONG: -72.32856484,
  Y_LAT: 42.50203218,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 205,
  TOWN: 'NEWBURY',
  X_COORD: 250948.97407,
  Y_COORD: 946851.64379,
  OLD_X_LONG: -70.8774874,
  OLD_Y_LAT: 42.7705959,
  X_LONG: -70.87928332,
  Y_LAT: 42.82020618,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 206,
  TOWN: 'NEWBURYPORT',
  X_COORD: 248926.72981,
  Y_COORD: 951584.59706,
  OLD_X_LONG: -70.9017895,
  OLD_Y_LAT: 42.8133295,
  X_LONG: -70.90358604,
  Y_LAT: 42.86293939,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 207,
  TOWN: 'NEWTON',
  X_COORD: 224030.46744,
  Y_COORD: 897935.81116,
  OLD_X_LONG: -71.2084263,
  OLD_Y_LAT: 42.3315436,
  X_LONG: -71.21023061,
  Y_LAT: 42.38115788,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 208,
  TOWN: 'NORFOLK',
  X_COORD: 214099.47589,
  Y_COORD: 874000.37102,
  OLD_X_LONG: -71.3295041,
  OLD_Y_LAT: 42.1162951,
  X_LONG: -71.33131148,
  Y_LAT: 42.16591134,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 209,
  TOWN: 'NORTH ADAMS',
  X_COORD: 67519.02573,
  Y_COORD: 938346.8787,
  OLD_X_LONG: -73.116565,
  OLD_Y_LAT: 42.6844094,
  X_LONG: -73.11841766,
  Y_LAT: 42.73402046,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 210,
  TOWN: 'NORTH ANDOVER',
  X_COORD: 233897.78793,
  Y_COORD: 935719.00142,
  OLD_X_LONG: -71.0864802,
  OLD_Y_LAT: 42.6713152,
  X_LONG: -71.08828142,
  Y_LAT: 42.72092638,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 211,
  TOWN: 'NORTH ATTLEBOROUGH',
  X_COORD: 213726.44672,
  Y_COORD: 857744.94122,
  OLD_X_LONG: -71.3343965,
  OLD_Y_LAT: 41.9699498,
  X_LONG: -71.336204,
  Y_LAT: 42.01956737,
  DX: -50,
  DY: -20
}, {
  TOWN_ID: 212,
  TOWN: 'NORTH BROOKFIELD',
  X_COORD: 152327.85929,
  Y_COORD: 891158.03348,
  OLD_X_LONG: -72.0778663,
  OLD_Y_LAT: 42.2694399,
  X_LONG: -72.07969264,
  Y_LAT: 42.31905474,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 213,
  TOWN: 'NORTH READING',
  X_COORD: 233856.80305,
  Y_COORD: 925751.42282,
  OLD_X_LONG: -71.0875681,
  OLD_Y_LAT: 42.5815884,
  X_LONG: -71.08936935,
  Y_LAT: 42.6312004,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 214,
  TOWN: 'NORTHAMPTON',
  X_COORD: 103184.54381,
  Y_COORD: 898018.01808,
  OLD_X_LONG: -72.6746546,
  OLD_Y_LAT: 42.3266516,
  X_LONG: -72.67649606,
  Y_LAT: 42.37626592,
  DX: -10,
  DY: 125
}, {
  TOWN_ID: 215,
  TOWN: 'NORTHBOROUGH',
  X_COORD: 187946.39443,
  Y_COORD: 896974.97172,
  OLD_X_LONG: -71.6462329,
  OLD_Y_LAT: 42.3231701,
  X_LONG: -71.6480483,
  Y_LAT: 42.37278445,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 216,
  TOWN: 'NORTHBRIDGE',
  X_COORD: 187207.0182,
  Y_COORD: 875524.15309,
  OLD_X_LONG: -71.6547308,
  OLD_Y_LAT: 42.1300364,
  X_LONG: -71.65654642,
  Y_LAT: 42.17965251,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 217,
  TOWN: 'NORTHFIELD',
  X_COORD: 122465.08874,
  Y_COORD: 936561.92125,
  OLD_X_LONG: -72.4459327,
  OLD_Y_LAT: 42.6757726,
  X_LONG: -72.44776836,
  Y_LAT: 42.72538374,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 218,
  TOWN: 'NORTON',
  X_COORD: 226183.93013,
  Y_COORD: 857124.19825,
  OLD_X_LONG: -71.1841312,
  OLD_Y_LAT: 41.9640445,
  X_LONG: -71.18593489,
  Y_LAT: 42.01366213,
  DX: -50,
  DY: -20
}, {
  TOWN_ID: 219,
  TOWN: 'NORWELL',
  X_COORD: 256380.97232,
  Y_COORD: 879156.50263,
  OLD_X_LONG: -70.8177358,
  OLD_Y_LAT: 42.1608133,
  X_LONG: -70.81953021,
  Y_LAT: 42.21042913,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 220,
  TOWN: 'NORWOOD',
  X_COORD: 225208.83427,
  Y_COORD: 881795.63106,
  OLD_X_LONG: -71.1948293,
  OLD_Y_LAT: 42.1861978,
  X_LONG: -71.19663326,
  Y_LAT: 42.2358134,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 221,
  TOWN: 'OAK BLUFFS',
  X_COORD: 276738.91629,
  Y_COORD: 798955.61521,
  OLD_X_LONG: -70.5818482,
  OLD_Y_LAT: 41.4370425,
  X_LONG: -70.58363663,
  Y_LAT: 41.48666493,
  DX: 50,
  DY: 20
}, {
  TOWN_ID: 222,
  TOWN: 'OAKHAM',
  X_COORD: 155011.63065,
  Y_COORD: 900099.76824,
  OLD_X_LONG: -72.0460293,
  OLD_Y_LAT: 42.3500987,
  X_LONG: -72.04785483,
  Y_LAT: 42.39971281,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 223,
  TOWN: 'ORANGE',
  X_COORD: 134921.01669,
  Y_COORD: 928747.9173,
  OLD_X_LONG: -72.2930925,
  OLD_Y_LAT: 42.6065767,
  X_LONG: -72.29492429,
  Y_LAT: 42.65618847,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 224,
  TOWN: 'ORLEANS',
  X_COORD: 326977.21094,
  Y_COORD: 837085.11232,
  OLD_X_LONG: -69.9727009,
  OLD_Y_LAT: 41.7738249,
  X_LONG: -69.9744739,
  Y_LAT: 41.82344426,
  DX: -20,
  DY: -50
}, {
  TOWN_ID: 225,
  TOWN: 'OTIS',
  X_COORD: 69061.04933,
  Y_COORD: 884922.29032,
  OLD_X_LONG: -73.0856359,
  OLD_Y_LAT: 42.2037957,
  X_LONG: -73.08748777,
  Y_LAT: 42.25341114,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 226,
  TOWN: 'OXFORD',
  X_COORD: 169694.96741,
  Y_COORD: 875414.06028,
  OLD_X_LONG: -71.8665311,
  OLD_Y_LAT: 42.1285635,
  X_LONG: -71.86835208,
  Y_LAT: 42.17817963,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 227,
  TOWN: 'PALMER',
  X_COORD: 132995.30067,
  Y_COORD: 882349.04808,
  OLD_X_LONG: -72.3111818,
  OLD_Y_LAT: 42.1887178,
  X_LONG: -72.31301405,
  Y_LAT: 42.23833338,
  DX: 10,
  DY: 100
}, {
  TOWN_ID: 228,
  TOWN: 'PAXTON',
  X_COORD: 164300.17056,
  Y_COORD: 895695.03369,
  OLD_X_LONG: -71.9330239,
  OLD_Y_LAT: 42.3109241,
  X_LONG: -71.93484657,
  Y_LAT: 42.36053857,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 229,
  TOWN: 'PEABODY',
  X_COORD: 243334.97617,
  Y_COORD: 920460.78674,
  OLD_X_LONG: -70.9725094,
  OLD_Y_LAT: 42.5334909,
  X_LONG: -70.97430773,
  Y_LAT: 42.58310334,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 230,
  TOWN: 'PELHAM',
  X_COORD: 124536.43344,
  Y_COORD: 903773.11178,
  OLD_X_LONG: -72.4163666,
  OLD_Y_LAT: 42.3808159,
  X_LONG: -72.41820151,
  Y_LAT: 42.43042973,
  DX: -10,
  DY: 125
}, {
  TOWN_ID: 231,
  TOWN: 'PEMBROKE',
  X_COORD: 257813.8238,
  Y_COORD: 868570.56037,
  OLD_X_LONG: -70.8014468,
  OLD_Y_LAT: 42.0654048,
  X_LONG: -70.8032408,
  Y_LAT: 42.1150215,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 232,
  TOWN: 'PEPPERELL',
  X_COORD: 191454.52225,
  Y_COORD: 935635.89954,
  OLD_X_LONG: -71.604246,
  OLD_Y_LAT: 42.6712598,
  X_LONG: -71.60606034,
  Y_LAT: 42.72087098,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 233,
  TOWN: 'PERU',
  X_COORD: 73274.59882,
  Y_COORD: 909897.99561,
  OLD_X_LONG: -73.0400826,
  OLD_Y_LAT: 42.4293058,
  X_LONG: -73.04193332,
  Y_LAT: 42.47891919,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 234,
  TOWN: 'PETERSHAM',
  X_COORD: 140693.14203,
  Y_COORD: 912376.04598,
  OLD_X_LONG: -72.2210668,
  OLD_Y_LAT: 42.4596577,
  X_LONG: -72.22289677,
  Y_LAT: 42.50927081,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 235,
  TOWN: 'PHILLIPSTON',
  X_COORD: 147812.64763,
  Y_COORD: 922667.13856,
  OLD_X_LONG: -72.1354417,
  OLD_Y_LAT: 42.5528096,
  X_LONG: -72.1372695,
  Y_LAT: 42.60242186,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 236,
  TOWN: 'PITTSFIELD',
  X_COORD: 55193.71763,
  Y_COORD: 912739.62034,
  OLD_X_LONG: -73.2604721,
  OLD_Y_LAT: 42.451734,
  X_LONG: -73.2623284,
  Y_LAT: 42.50134718,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 237,
  TOWN: 'PLAINFIELD',
  X_COORD: 82970.93816,
  Y_COORD: 919584.40773,
  OLD_X_LONG: -72.924232,
  OLD_Y_LAT: 42.5180117,
  X_LONG: -72.92607978,
  Y_LAT: 42.56762428,
  DX: -10,
  DY: 125
}, {
  TOWN_ID: 238,
  TOWN: 'PLAINVILLE',
  X_COORD: 213551.62194,
  Y_COORD: 862650.27285,
  OLD_X_LONG: -71.3363921,
  OLD_Y_LAT: 42.0141171,
  X_LONG: -71.33819965,
  Y_LAT: 42.06373427,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 239,
  TOWN: 'PLYMOUTH',
  X_COORD: 272135.2052,
  Y_COORD: 847701.45391,
  OLD_X_LONG: -70.6309841,
  OLD_Y_LAT: 41.8763358,
  X_LONG: -70.63277378,
  Y_LAT: 41.92595423,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 240,
  TOWN: 'PLYMPTON',
  X_COORD: 257222.68615,
  Y_COORD: 857243.03502,
  OLD_X_LONG: -70.8096968,
  OLD_Y_LAT: 41.9634662,
  X_LONG: -70.811491,
  Y_LAT: 42.01308383,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 241,
  TOWN: 'PRINCETON',
  X_COORD: 168645.48448,
  Y_COORD: 911844.15786,
  OLD_X_LONG: -71.8811932,
  OLD_Y_LAT: 42.4564954,
  X_LONG: -71.88301455,
  Y_LAT: 42.50610854,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 242,
  TOWN: 'PROVINCETOWN',
  X_COORD: 307817.19418,
  Y_COORD: 868620.35048,
  OLD_X_LONG: -70.1973288,
  OLD_Y_LAT: 42.0605718,
  X_LONG: -70.19910749,
  Y_LAT: 42.11018855,
  DX: -20,
  DY: -50
}, {
  TOWN_ID: 243,
  TOWN: 'QUINCY',
  X_COORD: 239492.61442,
  Y_COORD: 888895.81012,
  OLD_X_LONG: -71.0214348,
  OLD_Y_LAT: 42.2495296,
  X_LONG: -71.02323437,
  Y_LAT: 42.29914463,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 244,
  TOWN: 'RANDOLPH',
  X_COORD: 236856.38562,
  Y_COORD: 880921.06032,
  OLD_X_LONG: -71.0538851,
  OLD_Y_LAT: 42.1778622,
  X_LONG: -71.05568549,
  Y_LAT: 42.22747788,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 245,
  TOWN: 'RAYNHAM',
  X_COORD: 237856.90564,
  Y_COORD: 853522.31739,
  OLD_X_LONG: -71.0435495,
  OLD_Y_LAT: 41.93114,
  X_LONG: -71.04534963,
  Y_LAT: 41.98075793,
  DX: -50,
  DY: -20
}, {
  TOWN_ID: 246,
  TOWN: 'READING',
  X_COORD: 232402.45643,
  Y_COORD: 920577.40264,
  OLD_X_LONG: -71.1055756,
  OLD_Y_LAT: 42.5350726,
  X_LONG: -71.1073773,
  Y_LAT: 42.58468502,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 247,
  TOWN: 'REHOBOTH',
  X_COORD: 221133.52817,
  Y_COORD: 843760.72322,
  OLD_X_LONG: -71.2455371,
  OLD_Y_LAT: 41.8438803,
  X_LONG: -71.24734235,
  Y_LAT: 41.89349902,
  DX: -50,
  DY: -20
}, {
  TOWN_ID: 248,
  TOWN: 'REVERE',
  X_COORD: 240730.75858,
  Y_COORD: 907663.87614,
  OLD_X_LONG: -71.005112,
  OLD_Y_LAT: 42.4184289,
  X_LONG: -71.00691116,
  Y_LAT: 42.46804239,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 249,
  TOWN: 'RICHMOND',
  X_COORD: 46923.93343,
  Y_COORD: 905699.53453,
  OLD_X_LONG: -73.3591152,
  OLD_Y_LAT: 42.3867892,
  X_LONG: -73.360974,
  Y_LAT: 42.43640297,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 250,
  TOWN: 'ROCHESTER',
  X_COORD: 255010.45439,
  Y_COORD: 834633.33846,
  OLD_X_LONG: -70.8384981,
  OLD_Y_LAT: 41.7600654,
  X_LONG: -70.84029303,
  Y_LAT: 41.80968489,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 251,
  TOWN: 'ROCKLAND',
  X_COORD: 248779.63771,
  Y_COORD: 875617.43381,
  OLD_X_LONG: -70.9100124,
  OLD_Y_LAT: 42.1294618,
  X_LONG: -70.91180915,
  Y_LAT: 42.17907792,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 252,
  TOWN: 'ROCKPORT',
  X_COORD: 271878.30005,
  Y_COORD: 934169.72525,
  OLD_X_LONG: -70.6233733,
  OLD_Y_LAT: 42.6547832,
  X_LONG: -70.62516278,
  Y_LAT: 42.70439453,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 253,
  TOWN: 'ROWE',
  X_COORD: 84234.51483,
  Y_COORD: 939632.39589,
  OLD_X_LONG: -72.9128983,
  OLD_Y_LAT: 42.6986518,
  X_LONG: -72.9147458,
  Y_LAT: 42.74826273,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 254,
  TOWN: 'ROWLEY',
  X_COORD: 249236.34609,
  Y_COORD: 941232.14651,
  OLD_X_LONG: -70.898896,
  OLD_Y_LAT: 42.7201222,
  X_LONG: -70.90069246,
  Y_LAT: 42.76973294,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 255,
  TOWN: 'ROYALSTON',
  X_COORD: 143772.74558,
  Y_COORD: 936928.13198,
  OLD_X_LONG: -72.1860267,
  OLD_Y_LAT: 42.680904,
  X_LONG: -72.18785578,
  Y_LAT: 42.73051509,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 256,
  TOWN: 'RUSSELL',
  X_COORD: 87881.81567,
  Y_COORD: 879985.71802,
  OLD_X_LONG: -72.8568123,
  OLD_Y_LAT: 42.1622804,
  X_LONG: -72.85865837,
  Y_LAT: 42.21189622,
  DX: 10,
  DY: 100
}, {
  TOWN_ID: 257,
  TOWN: 'RUTLAND',
  X_COORD: 161532.19832,
  Y_COORD: 903917.15792,
  OLD_X_LONG: -71.9671438,
  OLD_Y_LAT: 42.3848138,
  X_LONG: -71.96896733,
  Y_LAT: 42.43442759,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 258,
  TOWN: 'SALEM',
  X_COORD: 248734.72118,
  Y_COORD: 917957.11334,
  OLD_X_LONG: -70.9069954,
  OLD_Y_LAT: 42.510633,
  X_LONG: -70.90879207,
  Y_LAT: 42.56024565,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 259,
  TOWN: 'SALISBURY',
  X_COORD: 252085.38482,
  Y_COORD: 955572.86446,
  OLD_X_LONG: -70.8628068,
  OLD_Y_LAT: 42.8490231,
  X_LONG: -70.86460235,
  Y_LAT: 42.89863266,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 260,
  TOWN: 'SANDISFIELD',
  X_COORD: 65789.32941,
  Y_COORD: 874380.66391,
  OLD_X_LONG: -73.1228177,
  OLD_Y_LAT: 42.1083509,
  X_LONG: -73.12467052,
  Y_LAT: 42.15796721,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 261,
  TOWN: 'SANDWICH',
  X_COORD: 284511.4119,
  Y_COORD: 829561.97507,
  OLD_X_LONG: -70.4845021,
  OLD_Y_LAT: 41.7117964,
  X_LONG: -70.48628806,
  Y_LAT: 41.76141632,
  DX: -20,
  DY: -50
}, {
  TOWN_ID: 262,
  TOWN: 'SAUGUS',
  X_COORD: 239843.71082,
  Y_COORD: 913255.43355,
  OLD_X_LONG: -71.0155037,
  OLD_Y_LAT: 42.4688125,
  X_LONG: -71.01730312,
  Y_LAT: 42.51842553,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 263,
  TOWN: 'SAVOY',
  X_COORD: 75293.12297,
  Y_COORD: 928408.77227,
  OLD_X_LONG: -73.0195641,
  OLD_Y_LAT: 42.5962471,
  X_LONG: -73.0214143,
  Y_LAT: 42.64585897,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 264,
  TOWN: 'SCITUATE',
  X_COORD: 260857.87163,
  Y_COORD: 883642.06685,
  OLD_X_LONG: -70.7630947,
  OLD_Y_LAT: 42.2008606,
  X_LONG: -70.76488772,
  Y_LAT: 42.25047607,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 265,
  TOWN: 'SEEKONK',
  X_COORD: 215164.58979,
  Y_COORD: 843069.844,
  OLD_X_LONG: -71.3174249,
  OLD_Y_LAT: 41.8377977,
  X_LONG: -71.31923197,
  Y_LAT: 41.88741648,
  DX: -50,
  DY: -20
}, {
  TOWN_ID: 266,
  TOWN: 'SHARON',
  X_COORD: 226217.32256,
  Y_COORD: 873196.98744,
  OLD_X_LONG: -71.1830078,
  OLD_Y_LAT: 42.1087504,
  X_LONG: -71.18481146,
  Y_LAT: 42.15836671,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 267,
  TOWN: 'SHEFFIELD',
  X_COORD: 46166.6756,
  Y_COORD: 874080.61245,
  OLD_X_LONG: -73.3599369,
  OLD_Y_LAT: 42.1020435,
  X_LONG: -73.36179572,
  Y_LAT: 42.15165987,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 268,
  TOWN: 'SHELBURNE',
  X_COORD: 102889.02024,
  Y_COORD: 928768.64203,
  OLD_X_LONG: -72.6834158,
  OLD_Y_LAT: 42.6034224,
  X_LONG: -72.68525748,
  Y_LAT: 42.6530342,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 269,
  TOWN: 'SHERBORN',
  X_COORD: 210290.78944,
  Y_COORD: 887084.48137,
  OLD_X_LONG: -71.3753287,
  OLD_Y_LAT: 42.2341519,
  X_LONG: -71.37713724,
  Y_LAT: 42.28376707,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 270,
  TOWN: 'SHIRLEY',
  X_COORD: 187934.77141,
  Y_COORD: 924687.7942,
  OLD_X_LONG: -71.6469531,
  OLD_Y_LAT: 42.5726566,
  X_LONG: -71.64876852,
  Y_LAT: 42.62226868,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 271,
  TOWN: 'SHREWSBURY',
  X_COORD: 182232.57039,
  Y_COORD: 892658.73206,
  OLD_X_LONG: -71.7154198,
  OLD_Y_LAT: 42.2842024,
  X_LONG: -71.71723695,
  Y_LAT: 42.33381711,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 272,
  TOWN: 'SHUTESBURY',
  X_COORD: 124393.3682,
  Y_COORD: 912194.69243,
  OLD_X_LONG: -72.4192058,
  OLD_Y_LAT: 42.4566143,
  X_LONG: -72.42104079,
  Y_LAT: 42.50622744,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 273,
  TOWN: 'SOMERSET',
  X_COORD: 229414,
  Y_COORD: 833945,
  OLD_X_LONG: -71.1463253,
  OLD_Y_LAT: 41.7552394,
  X_LONG: -71.14812803,
  Y_LAT: 41.80485893,
  DX: -50,
  DY: -20
}, {
  TOWN_ID: 274,
  TOWN: 'SOMERVILLE',
  X_COORD: 232799.10074,
  Y_COORD: 904532.73989,
  OLD_X_LONG: -71.1016596,
  OLD_Y_LAT: 42.3906146,
  X_LONG: -71.1034612,
  Y_LAT: 42.44022834,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 275,
  TOWN: 'SOUTH HADLEY',
  X_COORD: 110945.1671,
  Y_COORD: 890140.37903,
  OLD_X_LONG: -72.5792967,
  OLD_Y_LAT: 42.2566592,
  X_LONG: -72.58113574,
  Y_LAT: 42.30627416,
  DX: -10,
  DY: 125
}, {
  TOWN_ID: 276,
  TOWN: 'SOUTHAMPTON',
  X_COORD: 97762.77681,
  Y_COORD: 887455.90549,
  OLD_X_LONG: -72.7385668,
  OLD_Y_LAT: 42.2308807,
  X_LONG: -72.74040988,
  Y_LAT: 42.2804959,
  DX: -10,
  DY: 125
}, {
  TOWN_ID: 277,
  TOWN: 'SOUTHBOROUGH',
  X_COORD: 197551.22921,
  Y_COORD: 894524.04606,
  OLD_X_LONG: -71.5296979,
  OLD_Y_LAT: 42.3011939,
  X_LONG: -71.53151035,
  Y_LAT: 42.35080845,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 278,
  TOWN: 'SOUTHBRIDGE',
  X_COORD: 155827.1826,
  Y_COORD: 867885.61576,
  OLD_X_LONG: -72.0336847,
  OLD_Y_LAT: 42.0601253,
  X_LONG: -72.03550992,
  Y_LAT: 42.10974205,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 279,
  TOWN: 'SOUTHWICK',
  X_COORD: 94168.72877,
  Y_COORD: 867906.3945,
  OLD_X_LONG: -72.7785512,
  OLD_Y_LAT: 42.0544152,
  X_LONG: -72.78039529,
  Y_LAT: 42.104032,
  DX: 10,
  DY: 100
}, {
  TOWN_ID: 280,
  TOWN: 'SPENCER',
  X_COORD: 159409.89712,
  Y_COORD: 888634.34474,
  OLD_X_LONG: -71.9918458,
  OLD_Y_LAT: 42.2471194,
  X_LONG: -71.99366996,
  Y_LAT: 42.29673445,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 281,
  TOWN: 'SPRINGFIELD',
  X_COORD: 114041.84199,
  Y_COORD: 874422.65822,
  OLD_X_LONG: -72.5394492,
  OLD_Y_LAT: 42.1155084,
  X_LONG: -72.54128723,
  Y_LAT: 42.16512465,
  DX: 10,
  DY: 100
}, {
  TOWN_ID: 282,
  TOWN: 'STERLING',
  X_COORD: 177546.14577,
  Y_COORD: 910109.26582,
  OLD_X_LONG: -71.7729166,
  OLD_Y_LAT: 42.4411843,
  X_LONG: -71.77473521,
  Y_LAT: 42.49079758,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 283,
  TOWN: 'STOCKBRIDGE',
  X_COORD: 49571.5408,
  Y_COORD: 896286.6912,
  OLD_X_LONG: -73.3245248,
  OLD_Y_LAT: 42.3025816,
  X_LONG: -73.32638273,
  Y_LAT: 42.35219614,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 284,
  TOWN: 'STONEHAM',
  X_COORD: 233128.2053,
  Y_COORD: 913809.27152,
  OLD_X_LONG: -71.0971306,
  OLD_Y_LAT: 42.4741129,
  X_LONG: -71.09893209,
  Y_LAT: 42.52372588,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 285,
  TOWN: 'STOUGHTON',
  X_COORD: 232918.12184,
  Y_COORD: 874384.33747,
  OLD_X_LONG: -71.1019229,
  OLD_Y_LAT: 42.1191873,
  X_LONG: -71.10372451,
  Y_LAT: 42.16880351,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 286,
  TOWN: 'STOW',
  X_COORD: 199063.13567,
  Y_COORD: 908804.64595,
  OLD_X_LONG: -71.5113851,
  OLD_Y_LAT: 42.4297621,
  X_LONG: -71.51319708,
  Y_LAT: 42.47937548,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 287,
  TOWN: 'STURBRIDGE',
  X_COORD: 151171.67135,
  Y_COORD: 873187.5881,
  OLD_X_LONG: -72.0903731,
  OLD_Y_LAT: 42.107583,
  X_LONG: -72.09219975,
  Y_LAT: 42.15719932,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 288,
  TOWN: 'SUDBURY',
  X_COORD: 206308.25564,
  Y_COORD: 903805.2304,
  OLD_X_LONG: -71.4233945,
  OLD_Y_LAT: 42.384729,
  X_LONG: -71.42520425,
  Y_LAT: 42.43434279,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 289,
  TOWN: 'SUNDERLAND',
  X_COORD: 113268.61954,
  Y_COORD: 913263.36109,
  OLD_X_LONG: -72.5546053,
  OLD_Y_LAT: 42.4650756,
  X_LONG: -72.55644372,
  Y_LAT: 42.51468866,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 290,
  TOWN: 'SUTTON',
  X_COORD: 179307.82544,
  Y_COORD: 875950.13484,
  OLD_X_LONG: -71.750286,
  OLD_Y_LAT: 42.1337027,
  X_LONG: -71.75210404,
  Y_LAT: 42.18331878,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 291,
  TOWN: 'SWAMPSCOTT',
  X_COORD: 248781.56662,
  Y_COORD: 914096.13669,
  OLD_X_LONG: -70.9067522,
  OLD_Y_LAT: 42.4758725,
  X_LONG: -70.90854886,
  Y_LAT: 42.52548546,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 292,
  TOWN: 'SWANSEA',
  X_COORD: 223983.86381,
  Y_COORD: 834536.96596,
  OLD_X_LONG: -71.2115927,
  OLD_Y_LAT: 41.7607532,
  X_LONG: -71.21339709,
  Y_LAT: 41.81037268,
  DX: -50,
  DY: -20
}, {
  TOWN_ID: 293,
  TOWN: 'TAUNTON',
  X_COORD: 233664.77996,
  Y_COORD: 850443.08428,
  OLD_X_LONG: -71.0942709,
  OLD_Y_LAT: 41.9036075,
  X_LONG: -71.09607231,
  Y_LAT: 41.95322568,
  DX: -50,
  DY: -20
}, {
  TOWN_ID: 294,
  TOWN: 'TEMPLETON',
  X_COORD: 152912.20506,
  Y_COORD: 924387.43905,
  OLD_X_LONG: -72.0734914,
  OLD_Y_LAT: 42.5686212,
  X_LONG: -72.07531763,
  Y_LAT: 42.61823332,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 295,
  TOWN: 'TEWKSBURY',
  X_COORD: 222333.16562,
  Y_COORD: 929090.89855,
  OLD_X_LONG: -71.227814,
  OLD_Y_LAT: 42.6120672,
  X_LONG: -71.2296188,
  Y_LAT: 42.66167892,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 296,
  TOWN: 'TISBURY',
  X_COORD: 273646.42823,
  Y_COORD: 800658.05375,
  OLD_X_LONG: -70.6186355,
  OLD_Y_LAT: 41.4526633,
  X_LONG: -70.62042486,
  Y_LAT: 41.50228559,
  DX: 50,
  DY: 20
}, {
  TOWN_ID: 297,
  TOWN: 'TOLLAND',
  X_COORD: 74147.397,
  Y_COORD: 872385.84658,
  OLD_X_LONG: -73.0213475,
  OLD_Y_LAT: 42.091781,
  X_LONG: -73.02319774,
  Y_LAT: 42.14139746,
  DX: 10,
  DY: 100
}, {
  TOWN_ID: 298,
  TOWN: 'TOPSFIELD',
  X_COORD: 245718.98172,
  Y_COORD: 932002.59095,
  OLD_X_LONG: -70.942573,
  OLD_Y_LAT: 42.6372546,
  X_LONG: -70.94437057,
  Y_LAT: 42.68686609,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 299,
  TOWN: 'TOWNSEND',
  X_COORD: 182662.00617,
  Y_COORD: 935188.65139,
  OLD_X_LONG: -71.7114917,
  OLD_Y_LAT: 42.6670871,
  X_LONG: -71.71330875,
  Y_LAT: 42.71669832,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 300,
  TOWN: 'TRURO',
  X_COORD: 318882.95551,
  Y_COORD: 863251.50699,
  OLD_X_LONG: -70.0647453,
  OLD_Y_LAT: 42.0106419,
  X_LONG: -70.06652063,
  Y_LAT: 42.0602591,
  DX: -20,
  DY: -50
}, {
  TOWN_ID: 301,
  TOWN: 'TYNGSBOROUGH',
  X_COORD: 205813.86426,
  Y_COORD: 935068.51208,
  OLD_X_LONG: -71.4290826,
  OLD_Y_LAT: 42.6661774,
  X_LONG: -71.4308925,
  Y_LAT: 42.71578863,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 302,
  TOWN: 'TYRINGHAM',
  X_COORD: 59914.05772,
  Y_COORD: 889937.41097,
  OLD_X_LONG: -73.1975838,
  OLD_Y_LAT: 42.2473538,
  X_LONG: -73.19943851,
  Y_LAT: 42.29696884,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 303,
  TOWN: 'UPTON',
  X_COORD: 191385.06144,
  Y_COORD: 880743.3587,
  OLD_X_LONG: -71.6042747,
  OLD_Y_LAT: 42.1770827,
  X_LONG: -71.60608904,
  Y_LAT: 42.22669839,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 304,
  TOWN: 'UXBRIDGE',
  X_COORD: 188613.078,
  Y_COORD: 867670.746,
  OLD_X_LONG: -71.6375714,
  OLD_Y_LAT: 42.0593523,
  X_LONG: -71.63938658,
  Y_LAT: 42.10896906,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 305,
  TOWN: 'WAKEFIELD',
  X_COORD: 235702.15661,
  Y_COORD: 917088.64654,
  OLD_X_LONG: -71.0656264,
  OLD_Y_LAT: 42.5035214,
  X_LONG: -71.06742709,
  Y_LAT: 42.55313411,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 306,
  TOWN: 'WALES',
  X_COORD: 139310.34708,
  Y_COORD: 868252.93521,
  OLD_X_LONG: -72.2332666,
  OLD_Y_LAT: 42.0623279,
  X_LONG: -72.23509688,
  Y_LAT: 42.11194463,
  DX: 10,
  DY: 100
}, {
  TOWN_ID: 307,
  TOWN: 'WALPOLE',
  X_COORD: 220216.13356,
  Y_COORD: 877368.04064,
  OLD_X_LONG: -71.2554228,
  OLD_Y_LAT: 42.1464808,
  X_LONG: -71.2572283,
  Y_LAT: 42.19609676,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 308,
  TOWN: 'WALTHAM',
  X_COORD: 221220.00312,
  Y_COORD: 904298.57933,
  OLD_X_LONG: -71.2422936,
  OLD_Y_LAT: 42.3889074,
  X_LONG: -71.24409877,
  Y_LAT: 42.43852116,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 309,
  TOWN: 'WARE',
  X_COORD: 135308.48329,
  Y_COORD: 892538.87068,
  OLD_X_LONG: -72.2843135,
  OLD_Y_LAT: 42.2806476,
  X_LONG: -72.28614507,
  Y_LAT: 42.33026234,
  DX: -10,
  DY: 125
}, {
  TOWN_ID: 310,
  TOWN: 'WAREHAM',
  X_COORD: 266363.65017,
  Y_COORD: 835720.37522,
  OLD_X_LONG: -70.7018606,
  OLD_Y_LAT: 41.7689775,
  X_LONG: -70.70365207,
  Y_LAT: 41.8185969,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 311,
  TOWN: 'WARREN',
  X_COORD: 142139.92777,
  Y_COORD: 883537.4081,
  OLD_X_LONG: -72.2005973,
  OLD_Y_LAT: 42.2001458,
  X_LONG: -72.20242675,
  Y_LAT: 42.24976128,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 312,
  TOWN: 'WARWICK',
  X_COORD: 131105.85608,
  Y_COORD: 936622.04725,
  OLD_X_LONG: -72.3405291,
  OLD_Y_LAT: 42.6771283,
  X_LONG: -72.34236209,
  Y_LAT: 42.72673943,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 313,
  TOWN: 'WASHINGTON',
  X_COORD: 63671.37795,
  Y_COORD: 903009.24591,
  OLD_X_LONG: -73.155137,
  OLD_Y_LAT: 42.3656801,
  X_LONG: -73.15699063,
  Y_LAT: 42.41529407,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 314,
  TOWN: 'WATERTOWN',
  X_COORD: 226570.98986,
  Y_COORD: 902208.93408,
  OLD_X_LONG: -71.177405,
  OLD_Y_LAT: 42.3699311,
  X_LONG: -71.17920852,
  Y_LAT: 42.41954503,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 315,
  TOWN: 'WAYLAND',
  X_COORD: 211586.6215,
  Y_COORD: 900907.272,
  OLD_X_LONG: -71.3593538,
  OLD_Y_LAT: 42.3585789,
  X_LONG: -71.36116193,
  Y_LAT: 42.40819293,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 316,
  TOWN: 'WEBSTER',
  X_COORD: 171153.02681,
  Y_COORD: 866911.87876,
  OLD_X_LONG: -71.8484763,
  OLD_Y_LAT: 42.0520721,
  X_LONG: -71.85029683,
  Y_LAT: 42.10168892,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 317,
  TOWN: 'WELLESLEY',
  X_COORD: 217685.93631,
  Y_COORD: 894898.07615,
  OLD_X_LONG: -71.2855,
  OLD_Y_LAT: 42.3043648,
  X_LONG: -71.28730626,
  Y_LAT: 42.35397933,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 318,
  TOWN: 'WELLFLEET',
  X_COORD: 323211.82464,
  Y_COORD: 854092.3761,
  OLD_X_LONG: -70.0144197,
  OLD_Y_LAT: 41.9275237,
  X_LONG: -70.01619375,
  Y_LAT: 41.97714166,
  DX: -20,
  DY: -50
}, {
  TOWN_ID: 319,
  TOWN: 'WENDELL',
  X_COORD: 125675.34882,
  Y_COORD: 922949.25688,
  OLD_X_LONG: -72.4050082,
  OLD_Y_LAT: 42.5535497,
  X_LONG: -72.40684283,
  Y_LAT: 42.60316195,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 320,
  TOWN: 'WENHAM',
  X_COORD: 250661.46032,
  Y_COORD: 927988.7423,
  OLD_X_LONG: -70.8826682,
  OLD_Y_LAT: 42.6008158,
  X_LONG: -70.88446425,
  Y_LAT: 42.65042762,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 321,
  TOWN: 'WEST BOYLSTON',
  X_COORD: 176562.336,
  Y_COORD: 902110.93244,
  OLD_X_LONG: -71.78455,
  OLD_Y_LAT: 42.3691492,
  X_LONG: -71.78636891,
  Y_LAT: 42.41876314,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 322,
  TOWN: 'WEST BRIDGEWATER',
  X_COORD: 239197.46957,
  Y_COORD: 863554.46547,
  OLD_X_LONG: -71.0267152,
  OLD_Y_LAT: 42.0213958,
  X_LONG: -71.0285149,
  Y_LAT: 42.0710129,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 323,
  TOWN: 'WEST BROOKFIELD',
  X_COORD: 145536.05167,
  Y_COORD: 889499.97674,
  OLD_X_LONG: -72.1600358,
  OLD_Y_LAT: 42.2540691,
  X_LONG: -72.16186422,
  Y_LAT: 42.30368408,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 324,
  TOWN: 'WEST NEWBURY',
  X_COORD: 243438.44979,
  Y_COORD: 949388.63432,
  OLD_X_LONG: -70.9690581,
  OLD_Y_LAT: 42.7938897,
  X_LONG: -70.97085634,
  Y_LAT: 42.84349976,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 325,
  TOWN: 'WEST SPRINGFIELD',
  X_COORD: 104897.74084,
  Y_COORD: 875636.34651,
  OLD_X_LONG: -72.6502094,
  OLD_Y_LAT: 42.1253778,
  X_LONG: -72.65205024,
  Y_LAT: 42.17499396,
  DX: 10,
  DY: 100
}, {
  TOWN_ID: 326,
  TOWN: 'WEST STOCKBRIDGE',
  X_COORD: 44340.51944,
  Y_COORD: 897252.87513,
  OLD_X_LONG: -73.3882101,
  OLD_Y_LAT: 42.3102531,
  X_LONG: -73.39006964,
  Y_LAT: 42.35986757,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 327,
  TOWN: 'WEST TISBURY',
  X_COORD: 270716.97028,
  Y_COORD: 794742.98474,
  OLD_X_LONG: -70.6543932,
  OLD_Y_LAT: 41.3996768,
  X_LONG: -70.65618347,
  Y_LAT: 41.44929957,
  DX: 50,
  DY: 20
}, {
  TOWN_ID: 328,
  TOWN: 'WESTBOROUGH',
  X_COORD: 190594.89529,
  Y_COORD: 890861.37765,
  OLD_X_LONG: -71.6140025,
  OLD_Y_LAT: 42.2681664,
  X_LONG: -71.61581708,
  Y_LAT: 42.31778126,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 329,
  TOWN: 'WESTFIELD',
  X_COORD: 96165.4498,
  Y_COORD: 877186.14196,
  OLD_X_LONG: -72.7560831,
  OLD_Y_LAT: 42.1382206,
  X_LONG: -72.75792662,
  Y_LAT: 42.18783664,
  DX: 10,
  DY: 100
}, {
  TOWN_ID: 330,
  TOWN: 'WESTFORD',
  X_COORD: 204921.5727,
  Y_COORD: 926204.24714,
  OLD_X_LONG: -71.4400428,
  OLD_Y_LAT: 42.5863861,
  X_LONG: -71.44185298,
  Y_LAT: 42.63599806,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 331,
  TOWN: 'WESTHAMPTON',
  X_COORD: 94606.71506,
  Y_COORD: 896285.56014,
  OLD_X_LONG: -72.7783988,
  OLD_Y_LAT: 42.3099457,
  X_LONG: -72.78024289,
  Y_LAT: 42.35956017,
  DX: -10,
  DY: 125
}, {
  TOWN_ID: 332,
  TOWN: 'WESTMINSTER',
  X_COORD: 166923.25232,
  Y_COORD: 922368.39288,
  OLD_X_LONG: -71.9027351,
  OLD_Y_LAT: 42.5511672,
  X_LONG: -71.904557,
  Y_LAT: 42.60077948,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 333,
  TOWN: 'WESTON',
  X_COORD: 216471.21825,
  Y_COORD: 900958.90585,
  OLD_X_LONG: -71.30006,
  OLD_Y_LAT: 42.3589559,
  X_LONG: -71.30186663,
  Y_LAT: 42.40856993,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 334,
  TOWN: 'WESTPORT',
  X_COORD: 234604.47177,
  Y_COORD: 816109.2781,
  OLD_X_LONG: -71.0849593,
  OLD_Y_LAT: 41.5944463,
  X_LONG: -71.08676048,
  Y_LAT: 41.64406729,
  DX: -50,
  DY: -20
}, {
  TOWN_ID: 335,
  TOWN: 'WESTWOOD',
  X_COORD: 223904.44273,
  Y_COORD: 885578.90197,
  OLD_X_LONG: -71.2104643,
  OLD_Y_LAT: 42.2202996,
  X_LONG: -71.21226866,
  Y_LAT: 42.26991489,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 336,
  TOWN: 'WEYMOUTH',
  X_COORD: 245625.43003,
  Y_COORD: 882765.82431,
  OLD_X_LONG: -70.9476014,
  OLD_Y_LAT: 42.1940084,
  X_LONG: -70.9493991,
  Y_LAT: 42.24362393,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 337,
  TOWN: 'WHATELY',
  X_COORD: 105841.5158,
  Y_COORD: 910384.45914,
  OLD_X_LONG: -72.6444347,
  OLD_Y_LAT: 42.4382983,
  X_LONG: -72.64627539,
  Y_LAT: 42.48791161,
  DX: -10,
  DY: -50
}, {
  TOWN_ID: 338,
  TOWN: 'WHITMAN',
  X_COORD: 246345.77343,
  Y_COORD: 870097.65239,
  OLD_X_LONG: -70.9398874,
  OLD_Y_LAT: 42.079915,
  X_LONG: -70.9416849,
  Y_LAT: 42.12953157,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 339,
  TOWN: 'WILBRAHAM',
  X_COORD: 123040.08615,
  Y_COORD: 875589.44364,
  OLD_X_LONG: -72.430801,
  OLD_Y_LAT: 42.1269481,
  X_LONG: -72.43263628,
  Y_LAT: 42.17656424,
  DX: 10,
  DY: 100
}, {
  TOWN_ID: 340,
  TOWN: 'WILLIAMSBURG',
  X_COORD: 98781.83265,
  Y_COORD: 906503.43225,
  OLD_X_LONG: -72.7295491,
  OLD_Y_LAT: 42.4024777,
  X_LONG: -72.73139195,
  Y_LAT: 42.45209133,
  DX: -10,
  DY: 125
}, {
  TOWN_ID: 341,
  TOWN: 'WILLIAMSTOWN',
  X_COORD: 58362.41712,
  Y_COORD: 938563.98868,
  OLD_X_LONG: -73.2283202,
  OLD_Y_LAT: 42.6847474,
  X_LONG: -73.23017569,
  Y_LAT: 42.73435846,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 342,
  TOWN: 'WILMINGTON',
  X_COORD: 227477.57956,
  Y_COORD: 923428.68592,
  OLD_X_LONG: -71.1653877,
  OLD_Y_LAT: 42.5609301,
  X_LONG: -71.16719092,
  Y_LAT: 42.61054229,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 343,
  TOWN: 'WINCHENDON',
  X_COORD: 155015.14976,
  Y_COORD: 935282.91846,
  OLD_X_LONG: -72.0487337,
  OLD_Y_LAT: 42.6668266,
  X_LONG: -72.0505593,
  Y_LAT: 42.71643782,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 344,
  TOWN: 'WINCHESTER',
  X_COORD: 229094.9988,
  Y_COORD: 911308.84524,
  OLD_X_LONG: -71.1463036,
  OLD_Y_LAT: 42.4517639,
  X_LONG: -71.14810633,
  Y_LAT: 42.50137708,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 345,
  TOWN: 'WINDSOR',
  X_COORD: 73174.77327,
  Y_COORD: 919266.42951,
  OLD_X_LONG: -73.0433553,
  OLD_Y_LAT: 42.5136155,
  X_LONG: -73.0452061,
  Y_LAT: 42.56322812,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 346,
  TOWN: 'WINTHROP',
  X_COORD: 242765.51494,
  Y_COORD: 903114.16444,
  OLD_X_LONG: -70.9807264,
  OLD_Y_LAT: 42.3773611,
  X_LONG: -70.98252494,
  Y_LAT: 42.42697496,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 347,
  TOWN: 'WOBURN',
  X_COORD: 228414.99607,
  Y_COORD: 915202.85247,
  OLD_X_LONG: -71.1543782,
  OLD_Y_LAT: 42.4868447,
  X_LONG: -71.15618114,
  Y_LAT: 42.53645756,
  DX: 100,
  DY: -10
}, {
  TOWN_ID: 348,
  TOWN: 'WORCESTER',
  X_COORD: 174606.53889,
  Y_COORD: 891175.35078,
  OLD_X_LONG: -71.8078155,
  OLD_Y_LAT: 42.2706371,
  X_LONG: -71.80963499,
  Y_LAT: 42.32025193,
  DX: -10,
  DY: -175
}, {
  TOWN_ID: 349,
  TOWN: 'WORTHINGTON',
  X_COORD: 81247.117,
  Y_COORD: 905991.54869,
  OLD_X_LONG: -72.9424096,
  OLD_Y_LAT: 42.3953975,
  X_LONG: -72.94425784,
  Y_LAT: 42.4450112,
  DX: -10,
  DY: 125
}, {
  TOWN_ID: 350,
  TOWN: 'WRENTHAM',
  X_COORD: 211984.02936,
  Y_COORD: 866776.59567,
  OLD_X_LONG: -71.355233,
  OLD_Y_LAT: 42.0512931,
  X_LONG: -71.35704103,
  Y_LAT: 42.10090993,
  DX: 150,
  DY: 20
}, {
  TOWN_ID: 351,
  TOWN: 'YARMOUTH',
  X_COORD: 306290.66205,
  Y_COORD: 826239.46589,
  OLD_X_LONG: -70.2234329,
  OLD_Y_LAT: 41.6792502,
  X_LONG: -70.22521225,
  Y_LAT: 41.72887042,
  DX: -20,
  DY: -50
}];
