/* eslint-disable import/prefer-default-export */
import { format } from 'd3-format';

export const getObjByValue = (arr, value, key) => {
  let i = 0;
  const arrayMax = arr.length;
  for (; i < arrayMax; i += 1) {
    const item = arr[i];
    if (item[key] === value) {
      return item;
    }
  }
  return undefined;
};

export const round = (number, precision) => {
  const shift = (num, prec, reverseShift) => {
    let acc = prec;
    if (reverseShift) {
      acc = -prec;
    }
    const numArray = (`${num}`).split('e');
    return+(`${numArray[0]}e${numArray[1] ? (+numArray[1] + acc) : acc}`);
  };
  return shift(Math.round(shift(number, precision, false)), precision, true);
};

export const customFormat2 = format(',.2f');
export const customFormat0 = format(',.0f');
export const customFormat1 = format(',.1f');
export const customFormatPercent = (number) => format('.0%')(number / 100);
export const customFormatPercent2 = (number) => format('.2%')(number / 100);

export const hexToHSL = (hex) => {
  // Convert hex to RGB first
  let r = 0; let g = 0; let
    b = 0;
  if (hex && hex.length === 4) {
    r = `0x${hex[1]}${hex[1]}`;
    g = `0x${hex[2]}${hex[2]}`;
    b = `0x${hex[3]}${hex[3]}`;
  } else if (hex && hex.length === 7) {
    r = `0x${hex[1]}${hex[2]}`;
    g = `0x${hex[3]}${hex[4]}`;
    b = `0x${hex[5]}${hex[6]}`;
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return({ h, s, l });
};

export const toProperCase = (string) => {
  const words = string.split(/[\s|_]+/);
  const cleanString = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  return cleanString.join(' ');
};

export const inMil = (num) => (num / 1000000).toFixed(1);
export const inBil = (num) => (num / 1000000000).toFixed(1);

export const deltaPct = (newNum, oldNum) => (((newNum - oldNum) / oldNum) * 100).toFixed(1);

export const withCommas = (num) => {
  const numParts = num.toString().split('.');
  numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return numParts.join('.');
};
