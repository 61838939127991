import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  ComposableMap, ZoomableGroup, Geographies, Geography, Annotations, Annotation
} from 'react-simple-maps';
import { scaleQuantile } from 'd3-scale';
import { Motion, spring } from 'react-motion';
import {
  hexToHSL, toProperCase, customFormatPercent2
} from '../../utils';
import LegendQuantile from '../Legend/Quantile';

import { FlowContext } from './context';
import './index.css';

class LinkedMap extends Component {
  constructor(props) {
    super(props);
    const {
      annotations
    } = this.props;
    this.state = {
      annotations
    };
    this.handleMoveEnd = this.handleMoveEnd.bind(this);
  }

  handleMove = (geo, town) => {
    const { updateContext } = this.context;
    updateContext(geo.id, 'activeGeoID');
    updateContext(town, 'activeTown');
  };

  handleLeave = () => {
    const { updateContext } = this.context;
    updateContext('', 'activeGeoID');
    updateContext('', 'activeTown');
  };

  handleMoveEnd(center) {
    const { mapIndex, otherMapIndex } = this.props;
    const { updateContext, mapState } = this.context;
    otherMapIndex.forEach((map) => {
      mapState[map].center = center;
    });
    mapState[mapIndex].panCenter = center;
    updateContext(mapState, 'mapState');
  }

  render() {
    const {
      annotations
    } = this.state;
    const {
      allData, geography, geoDisplayField, scaleBreaks, scaleColors, dataFormat, dataField, dataCentroid, dataUnits, legend, tooltip, nullValue, mapIndex, dataDX, dataDY, dataBase, mapTitle
    } = this.props;
    const {
      activeGeoID, activeTown, mapState, zoom, disablePan
    } = this.context;
    const { center } = mapState[mapIndex];

    const selectedTownData = allData.find((s) => s.FIPS_CODE === activeGeoID) || {};
    const withinTownData = allData.find((s) => (s.TOWN).replace(' ', '_') === dataField) || {};
    const withinTownMetric = withinTownData[dataField];
    const withinTownPercent = (withinTownMetric && dataBase) ? customFormatPercent2(withinTownMetric / dataBase * 100) : '';
    const selectedMetric = selectedTownData[dataField];
    const selectedMetricPercent = (selectedMetric && dataBase) ? customFormatPercent2(selectedMetric / dataBase * 100) : '';

    if (allData.length === 0) {
      return<div className="container">Loading...</div>;
    }

    const colorScale = scaleQuantile();
    colorScale.domain(scaleBreaks).range(scaleColors);
    console.log(`${selectedMetric}town: ${activeTown}datafield: ${dataField}`);
    return(
      <React.Fragment>
        <div className="map-title">
          <span dangerouslySetInnerHTML={{ __html: `${mapTitle}<br>` }} />
          {selectedMetric && activeTown && activeTown.replace(' ', '_') !== dataField && (
            <span dangerouslySetInnerHTML={{ __html: ` Of these trips, <strong>${dataFormat(selectedMetric)}</strong>${selectedMetricPercent && ` (<strong>${selectedMetricPercent}</strong>)`} were ${mapIndex === 1 ? 'from' : 'to'} <strong>${toProperCase(activeTown)}</strong>.` }} />
          )}
          {withinTownMetric && (activeTown.replace(' ', '_') === dataField || !activeTown) && (
            <span dangerouslySetInnerHTML={{ __html: ` Of these trips, <strong>${dataFormat(withinTownMetric)}</strong>${withinTownPercent && ` (<strong>${withinTownPercent}</strong>)`} were within <strong>${toProperCase(dataField)}</strong>.` }} />
          )}
        </div>
        <div className="wrapperStyles">
          {legend.showLegend && (
          <div className="wrapperLegendStyles">
            <span className="legendTitle">{legend.title}</span>
            <LegendQuantile
              scale={colorScale}
              direction={legend.direction || 'column'}
              labelMargin={legend.direction === 'row' ? '1px' : '0 5px 0 0'}
              shape="rect"
              labelFormat={dataFormat}
              itemDirection={legend.itemDirection || 'row'}
              shapeWidth={legend.direction === 'row' && '100%'}
              shapeMargin={legend.direction === 'row' && '1px'}
            />
          </div>
          )}
          <div className="wrapperMapStyles">
            <Motion
              defaultStyle={{
                zoom,
                x: center[0],
                y: center[1]
              }}
              style={{
                zoom: spring(zoom, { stiffness: 200, damping: 30 }),
                x: spring(center[0], { stiffness: 200, damping: 30 }),
                y: spring(center[1], { stiffness: 200, damping: 30 })
              }}
            >
              { /* eslint-disable no-shadow */
                // eslint-disable-next-line no-unused-vars
                ({ zoom, x, y }) => (
                  <ComposableMap
                    projection="mercator"
                    projectionConfig={{
                      scale: 12000
                    }}
                    width={980}
                    height={560}
                    style={{
                      width: '100%',
                      height: 'auto'
                    }}
                  >
                    <ZoomableGroup center={center[0] === x && center[1] === y ? center : [x, y]} zoom={zoom} disablePanning={disablePan} onMoveEnd={this.handleMoveEnd}>
                      <Geographies geography={geography} disableOptimization>
                        {(geographies, projection) => geographies.map((geo, i) => {
                          const townData = allData.find((s) => s.FIPS_CODE === geo.id) || {};
                          const geoLabel = townData[geoDisplayField];
                          const metric = townData[dataField];
                          let fill = colorScale(metric);
                          const tooltipMetric = metric && dataUnits ? `${dataFormat(metric)} ${dataUnits}` : dataFormat(metric);
                          const tooltipVal = metric && (Number(metric) !== nullValue) ? tooltipMetric : 'No Data';
                          const active = activeGeoID && activeGeoID === geo.id;
                          const selected = dataField === geoLabel.replace(' ', '_');
                          const fillHSL = hexToHSL(fill);
                          let strokeColor = '#fff';
                          let strokeWidth = 0.5;
                          if (selected) {
                            strokeColor = '#fff';
                            strokeWidth = 2.0;
                            fill = '#535353';
                          } else if (active) {
                            strokeWidth = 2.0;
                          } else if (fillHSL.l && Number(fillHSL.l) > 85 && Number(metric) !== nullValue) {
                            strokeColor = '#ddd';
                          }
                          return(
                            <Geography
                          // eslint-disable-next-line react/no-array-index-key
                              key={`${i}two`}
                              cacheId={`${i}two`}
                              round
                              geography={geo}
                              projection={projection}
                              data-html
                              data-tip={`<strong>${geoLabel}</strong><br><strong>${tooltip.dataLabel}</strong>: ${tooltipVal}`}
                              onMouseMove={(g) => this.handleMove(g, geoLabel, metric)}
                              onMouseLeave={() => this.handleLeave()}
                              style={{
                                default: {
                                  fill: (Number(metric) === nullValue && !selected) ? '#dcdcdc' : fill,
                                  fillOpacity: active ? '0.7' : '1',
                                  stroke: strokeColor,
                                  strokeWidth,
                                  outline: 'none'
                                },
                                hover: {
                                  fill: (Number(metric) === nullValue) ? '#dcdcdc' : fill,
                                  stroke: 'rgba(255,255,255,1.0)',
                                  fillOpacity: '0.7',
                                  strokeWidth: 2.00,
                                  outline: 'true'
                                },
                                pressed: {
                                  fill: (Number(metric) === nullValue) ? '#dcdcdc' : fill,
                                  stroke: 'rgba(255,255,255,1.0)',
                                  fillOpacity: '0.3',
                                  strokeWidth: 2.00,
                                  outline: 'true'
                                }
                              }}
                            />
                          );
                        })}

                      </Geographies>
                      {annotations && (
                      <Annotations>
                        {annotations.map((annotation) => {
                          const {
                            dx, dy, coordinates, strokeWidth, label
                          } = annotation;
                          return(
                            <Annotation
                              dx={dx}
                              dy={dy}
                              subject={coordinates}
                              strokeWidth={strokeWidth}
                              stroke="black"
                            >
                              <text>{label}</text>
                            </Annotation>
                          );
                        })}
                      </Annotations>
                      )}
                      { dataCentroid && dataDX && dataDY
                        && (
                        <Annotation
                          dx={dataDX}
                          dy={dataDY}
                          subject={dataCentroid}
                          strokeWidth={3}
                          stroke="black"
                        >
                          <text style={{ fontWeight: 700 }}>{`${dataField.replace('_', ' ')}`}</text>
                        </Annotation>
                        )
                      }
                    </ZoomableGroup>
                  </ComposableMap>
                )}
            </Motion>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

LinkedMap.contextType = FlowContext;

LinkedMap.propTypes = {
  mapTitle: PropTypes.string,
  allData: PropTypes.array,
  geography: PropTypes.object,
  defaultCenter: PropTypes.array,
  defaultZoom: PropTypes.number,
  defaultDisablePan: PropTypes.bool,
  geoDisplayField: PropTypes.string,
  scaleBreaks: PropTypes.array,
  scaleColors: PropTypes.array,
  dataFormat: PropTypes.func,
  dataField: PropTypes.string,
  dataCentroid: PropTypes.array,
  dataDX: PropTypes.number,
  dataDY: PropTypes.number,
  dataBase: PropTypes.number,
  dataUnits: PropTypes.string,
  areas: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    coordinates: PropTypes.array,
    zoom: PropTypes.number
  })),
  legend: PropTypes.shape({
    title: PropTypes.string,
    direction: PropTypes.oneOf(['row', 'column']),
    itemDirection: PropTypes.oneOf(['row', 'column']),
    showLegend: PropTypes.bool
  }),
  tooltip: PropTypes.shape({
    dataLabel: PropTypes.string,
    type: PropTypes.oneOf(['light', 'dark'])
  }),
  nullValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  annotations: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    strokeWidth: PropTypes.number,
    coordinates: PropTypes.array,
    dx: PropTypes.number,
    dy: PropTypes.number
  })),
  mapIndex: PropTypes.number,
  otherMapIndex: PropTypes.arrayOf(PropTypes.number)
};

LinkedMap.defaultProps = {
  legend: {
    direction: 'column',
    itemDirection: 'row',
    showLegend: true
  },
  tooltip: {
    type: 'light'
  },
  geoDisplayField: 'TOWN',
  defaultCenter: [-71.63627925574303, 42.13276308419552],
  defaultZoom: 1.1,
  defaultDisablePan: true
};

export default LinkedMap;
