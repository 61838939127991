import React, { Component } from 'react';
import axios from 'axios';
import csv from 'csvtojson';
import {
  Header, Footer, IllustratedHeader, ColoredHeading, CompHeading
} from '@massds/mayflower-react';

import Table from './components/Table';
import DeltaTripsSection from './sections/DeltaTripsSection';
import TotalTripsSection from './sections/TotalTripsSection';
import FlowSection from './sections/FlowSection';
import SpeedSection from './sections/SpeedSection';
import FootNote from './components/FootNote';
import FootNoteLink from './components/FootNote/FootNoteLink';

import masstowns from './data/masstowns.topo.json';
import content from './data/Content.data';
import { mapAreas } from './sections/mapOptions.data';

import './App.css';

const {
  data: {
    totalRidesInMil,
    totalRidesInMil2018,
    totalRidesInMil2017,
    // totalFeeInMil,
    totalRidesDeltaPct2018,
    totalRidesDeltaPct2017
  },
  footerProps,
  headerProps,
  illustratedHeader
} = content;


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allData: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const _this = this;
    axios.all([axios.get('../data/RIDESHARE_STATS.csv')])
      .then(axios.spread((result) => {
        const { data } = result;
        _this.parseData(data, 'allData');
      }))
      // eslint-disable-next-line no-console
      .catch((error) => { console.log(error); });
    axios.all([axios.get('../data/RIDESHARE_MATRIX_FROM.csv')])
      .then(axios.spread((result) => {
        const { data } = result;
        _this.parseData(data, 'matrixData');
      }))
      // eslint-disable-next-line no-console
      .catch((error) => { console.log(error); });
    axios.all([axios.get('../data/RIDESHARE_MATRIX_TO.csv')])
      .then(axios.spread((result) => {
        const { data } = result;
        _this.parseData(data, 'matrixDataTransposed');
      }))
      // eslint-disable-next-line no-console
      .catch((error) => { console.log(error); });
  };

  parseData = (data, name) => {
    const parsedData = [];
    csv({
      noheader: false
    }).fromString(data)
      .subscribe((json) => {
        parsedData.push(json);
      })
      .on('done', () => {
        this.setState({ [name]: parsedData });
      })
      .on('error', (err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  }

  render() {
    const { allData, matrixData, matrixDataTransposed } = this.state;
    return(
      <div className="App">
        <Header {...headerProps} />
        <IllustratedHeader {...illustratedHeader}>
          <hr />
          Data Source:
          <a href="https://www.mass.gov/orgs/department-of-public-utilities"><span className="credit"> Department of Public Utilities (DPU)</span></a>
        </IllustratedHeader>
        <main className="main-content">
          <section className="main-content--two">
            <ColoredHeading text="2019 Growth in Ridesharing Across Massachusetts" color="blue" centered />
            <p>
              {`In 2019, there were about ${totalRidesInMil} million TNC rides`}
              <FootNoteLink index={1} />
              {` in Massachusetts  – ${totalRidesDeltaPct2018}% more than the ${totalRidesInMil2018} million rides in 2018, and ${totalRidesDeltaPct2017}% more than the ${totalRidesInMil2017} million rides in 2017. `}
              This increase happened across the entire state. The largest increases in numbers of rides from 2018 to 2019 happened in cities like Boston (approximately 3 million more), Worcester (approximately 314,000 more), and Springfield (approximately 233,000 more).
              That said, many cities and towns with smaller numbers of rides saw a significant percentage increase in rides when compared to 2018 ridership. For example, in Wareham, ridesharing companies reported 25,652 rides in 2019, 116.9% more rides than in 2018. The data also shows high percentage growth in southeastern Massachusetts, for example in Fall River (237,276 rides in 2019, representing 76.9% more rides than in 2018) and in New Bedford (281,498 rides in 2019, representing 70.7% more rides than in 2018).
              <br />
              <br />
              TNC rides increased considerably across the Cape and Islands in 2019 when compared to 2018,  including in Nantucket (63,119 more), Barnstable (52,803 more), Falmouth (24,732 more), Oak Bluffs (15,657 more), and Provincetown (13,392 more). Ten cents from every ride originating in these municipalities is returned to them through the per-ride assessment. These assessments on the rideshare companies have been allocated towards mitigating the impacts of TNCs on the region. Funds collected from the 2018 rides, for example, funded cobblestone repairs on streets in Nantucket and traffic safety devices in Provincetown.
              <br />
              <br />
              Year over year, the increase in the number of rides is dramatic in other parts of the state too. Rides starting in Beverly increased from 121,950 in 2017 to 165,270 in 2018 and then to 220,311 in 2019. Similarly, rides starting in Marlborough have grown dramatically from 84,122 in 2017 to 132,263 in 2018 and then to 184,387 in 2019. Funds collected from the per ride assessment over the years have enabled traffic safety improvements in Marlborough and the reconstruction of a bridge in Beverly.
            </p>
            {allData && (
              <DeltaTripsSection
                allData={allData}
                geography={masstowns}
                areas={mapAreas}
              />
            )}
            <ColoredHeading text="Total Rides and Rides Per Person in 2019" color="blue" centered />
            <p>
              {`Although ${totalRidesInMil} million TNC rides make up a small percentage of the estimated 7.1 billion passenger vehicle trips in 2019`}
              <FootNoteLink index={2} />
              , many of the rideshare trends that emerged in 2018 continued to develop in 2019, further demonstrating that that TNC use is an important thread in the fabric of Massachusetts transportation, especially when it comes to getting around cities and getting to the airport. For further context, there were approximately 382.4 million public transit rides in the Commonwealth in 2019.
              <FootNoteLink index={3} />
              {' '}
              For example:
            </p>
            <ul>
              <li>
                78.2% of all TNC rides in Massachusetts began in Suffolk and Middlesex counties.
              </li>
              <li>
                45.3 million rides began in Boston – the most of any city or town. Cambridge is second with 7.9 million rides.
              </li>
              <li>
                TNC rides to and from Boston Logan International Airport totaled approximately 8.4 million, and are included in the total number of rides in Boston. There were approximately 4.85 million TNC rides to the Airport, and approximately 3.54 million TNC rides leaving the Airport.
                <FootNoteLink index={4} />
              </li>
              <li>
                More people used ridesharing to leave large population centers like Cambridge, Somerville, Brookline, Springfield, and Worcester than to enter them. (This is true for Boston, too, when we remove the airport.)
              </li>
            </ul>
            {allData && (
              <TotalTripsSection
                allData={allData}
                geography={masstowns}
                areas={mapAreas}
              />
            )}
            <CompHeading title="Table of Total Rides Started and Rides Started Per Person by Municipality" level={4} centered />
            <p>
              Use this table to explore the TNC rideshare data. You can sort by column headings, or use the search bar to look for a city or town you’re interested in.
            </p>
            {allData && allData.length > 0
              && (
              <Table
                id="municipality"
                data={allData}
                headRow="TOWN"
                pageSize={10}
                pagination
              />
              )
            }
          </section>
          <section className="main-content--two">
            {matrixData && matrixDataTransposed && (
              <FlowSection
                allData={{ matrixData, matrixDataTransposed, fullData: allData }}
                geography={masstowns}
                defaultTown="BOSTON"
                defaultCentroid={[-71.10457763, 42.30844048]}
                areas={mapAreas}
                showZoom
              />
            )}
          </section>
          <section className="main-content--two">
            {allData && (
              <SpeedSection
                allData={allData}
                geography={masstowns}
                areas={mapAreas}
              />
            )}
          </section>
        </main>
        <FootNote />
        <Footer {...footerProps} />
      </div>
    );
  }
}

export default App;
