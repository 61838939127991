import React from 'react';
import './index.css';

const InTextButton = (button) => {
  const onClickCallback = (e) => {
    if (typeof button.onClick === 'function') {
      e.preventDefault();
      button.onClick(e);
    }
  };
  return(
    <button
      className="ma__button--inline"
      type="button"
      title={button.info}
      aria-label={button.info}
      onClick={(e) => onClickCallback(e)}
    >
      {button.children}
    </button>
  );
};


export default InTextButton;
