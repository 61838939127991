import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { scaleThreshold } from 'd3-scale';
import { quantile, ascending } from 'd3-array';
import TagManager from 'react-gtm-module';
import { schemeBuPu } from 'd3-scale-chromatic';
import {
  ColoredHeading, InputTextFuzzy
} from '@massds/mayflower-react';


import LinkedMap from '../components/LinkedMap';
import { FlowContext } from '../components/LinkedMap/context';
import IconButton from '../components/Button';
import LegendThreshold from '../components/Legend/Threshold';

import { customFormat0, toProperCase } from '../utils';
import townCentroid from '../data/TownCentroids';

const onlyUnique = (value, index, self) => self.indexOf(value) === index;

const getBreaks = (data) => {
  data.sort(ascending);
  const unique = data.filter(onlyUnique);
  let breaks = [];
  if (unique.length > 50) {
    breaks = [parseInt(quantile(data, 0.17), 10), parseInt(quantile(data, 0.33), 10), parseInt(quantile(data, 0.5), 10), parseInt(quantile(data, 0.66), 10), parseInt(quantile(data, 0.83), 10), parseInt(quantile(data, 1), 10)];
  } else if (unique.length > 25) {
    breaks = [parseInt(quantile(data, 0.33), 10), parseInt(quantile(data, 0.66), 10), parseInt(quantile(data, 1.00), 10)];
  } else {
    breaks = [parseInt(quantile(data, 0.5), 10), parseInt(quantile(data, 1.00), 10)];
  }
  return breaks.filter(onlyUnique);
};


class FlowSection extends Component {
  constructor(props) {
    super(props);
    const {
      defaultTown,
      areas,
      defaultCenter,
      defaultZoom,
      defaultDisablePan,
      defaultCentroid
    } = this.props;
    this.state = {
      selectedTown: defaultTown,
      selectedCentroid: defaultCentroid,
      selectedDX: 100,
      selectedDY: -10,
      selectedOriginTrips: 45295938,
      selectedDestinationTrips: 45363184,
      /* eslint-disable react/no-unused-state */
      activeGeoID: '',
      activeTown: '',
      // center: defaultCenter,
      zoom: defaultZoom,
      areas,
      disablePan: defaultDisablePan,
      mapState: [{
        panCenter: defaultCenter,
        center: defaultCenter
      }, {
        panCenter: defaultCenter,
        center: defaultCenter
      }],
      updateContext: this.setContext.bind(this)
    };
    this.handleMuniSelection = this.handleMuniSelection.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleZoomIn = this.handleZoomIn.bind(this);
    this.handleZoomOut = this.handleZoomOut.bind(this);
  }

  setContext = (value, field) => {
    this.setState({
      [field]: value
    });
  }

  getTownFromCentroid = (townName) => townCentroid
    .find((town) => townName && town.TOWN.toLowerCase().replace(' ', '_') === townName.toLowerCase());

  getTownFromFullData = (townName) => {
    const {
      allData: {
        fullData
      }
    } = this.props;
    return fullData
      .find((muni) => townName && muni.TOWN.toLowerCase().replace(' ', '_') === townName.toLowerCase());
  }

  handleMuni = (value) => {
    const town = this.getTownFromCentroid(value);
    if (town) {
      this.setState({
        selectedTown: value,
        selectedCentroid: [town.X_LONG, town.Y_LAT],
        selectedDX: town.DX,
        selectedDY: town.DY
      });
      TagManager.dataLayer({
        dataLayer: {
          event: 'gtm.input-fuzzy-select-suggestion',
          inputFuzzySelectedValue: value,
          inputFuzzyID: 'muni-selection'
        }
      });
      const muni = this.getTownFromFullData(value);
      if (muni) {
        this.setState({
          selectedOriginTrips: muni.ORIGIN_TRIPS_2019,
          selectedDestinationTrips: muni.DESTINATION_TRIPS_2019
        });
      }
    }
  };

  handleMuniSelection(e, { suggestion, method }) {
    let value = suggestion.item.value;
    // If the user enters an exact match and hits enter, there are no suggestions.
    if (!suggestion.item.value && method === 'enter') {
      // What the user entered must match the value from data, so look it up.
      const muni = this.getTownFromFullData(e.currentTarget.value);
      if (muni) {
        value = muni.TOWN;
      }
    }
    this.handleMuni(value);
  }

  handleReset() {
    const {
      defaultCenter,
      defaultZoom,
      defaultDisablePan,
      annotations
    } = this.props;
    const { mapState } = this.state;
    if (mapState) {
      mapState.forEach((map, index) => {
        const { panCenter, center } = map;
        if (panCenter && panCenter !== center) {
          mapState[index].center = panCenter;
          this.setState({
            mapState
          }, () => {
            mapState[index].center = defaultCenter;
            this.setState({
              zoom: defaultZoom,
              disablePan: defaultDisablePan,
              mapState,
              annotations
            });
          });
        }
      });
    }
  }

  handleZoomIn() {
    const { zoom, mapState } = this.state;
    const newZoom = zoom < 10 ? Number(zoom) + 1 : zoom;
    if (mapState) {
      mapState.forEach((map, index) => {
        const { panCenter, center } = map;
        if (panCenter && panCenter !== center) {
          mapState[index].center = panCenter;
          this.setState({
            mapState
          }, () => {
            this.setState({
              zoom: newZoom,
              disablePan: false,
              annotations: []
            });
          });
        } else {
          this.setState({
            zoom: newZoom,
            disablePan: false,
            annotations: []
          });
        }
      });
    }
  }

  handleZoomOut() {
    const {
      zoom, mapState
    } = this.state;
    const {
      defaultZoom, defaultDisablePan, annotations
    } = this.props;
    const newZoom = zoom - 1;
    if (mapState) {
      mapState.forEach((map, index) => {
        const { panCenter, center } = map;
        if (panCenter && panCenter !== center) {
          mapState[index].center = panCenter;
          this.setState({
            mapState
          }, () => {
            if (newZoom <= defaultZoom) {
              mapState[index].center = panCenter;
              this.setState({
                mapState,
                zoom: defaultZoom,
                disablePan: defaultDisablePan,
                annotations
              });
            } else {
              this.setState({
                zoom: newZoom,
                disablePan: false
              });
            }
          });
        } else if (newZoom <= defaultZoom) {
          mapState[index].center = panCenter;
          this.setState({
            mapState,
            zoom: defaultZoom,
            disablePan: defaultDisablePan,
            annotations
          });
        } else {
          this.setState({
            zoom: newZoom,
            disablePan: false
          });
        }
      });
    }
  }

  render() {
    const {
      allData: { matrixData, matrixDataTransposed }, geography
    } = this.props;
    const {
      selectedTown, selectedCentroid, selectedDX, selectedDY, selectedOriginTrips, selectedDestinationTrips
    } = this.state;
    const typeaheadArray = [];
    const destArray = [];
    const townArray = [];
    const townList = Object.keys(matrixData[0]);
    townList.forEach((item, index) => {
      if (index > 3 && item) {
        typeaheadArray.push({
          text: toProperCase(item),
          value: item
        });
        townArray.push(item);
      }
    });
    matrixData.filter((item) => Number(item[selectedTown]) > 0).forEach((item) => {
      if ((item.TOWN).replace(' ', '_') === selectedTown) {
        townArray.forEach((town) => {
          if (town.replace(' ', '_') !== selectedTown && Number(item[town]) > 0) {
            destArray.push(Math.round(10 * Number(item[town])) / 10);
          }
        });
      }
    });
    matrixDataTransposed.filter((item) => Number(item[selectedTown]) > 0).forEach((item) => {
      if ((item.TOWN).replace(' ', '_') === selectedTown) {
        townArray.forEach((town) => {
          if (town.replace(' ', '_') !== selectedTown && Number(item[town]) > 0) {
            destArray.push(Math.round(10 * Number(item[town])) / 10);
          }
        });
      }
    });
    let breaks; let colorScale; let
      colorRange;
    if (destArray && destArray.length > 1) {
      breaks = getBreaks(destArray);
      colorScale = scaleThreshold();
      colorRange = schemeBuPu[breaks.length + 1];
      colorScale.domain(breaks).range(colorRange);
    } else if (destArray && destArray.length === 1) {
      breaks = [destArray[0], 100];
      colorScale = scaleThreshold();
      colorRange = schemeBuPu[breaks.length + 1];
      colorScale.domain(breaks).range(colorRange);
    } else {
      breaks = [1, 100];
      colorScale = scaleThreshold();
      colorRange = schemeBuPu[3];
      colorScale.domain([1, 100]).range(colorRange);
    }
    return(
      <Fragment>
        <ColoredHeading text="TNC Travel Patterns Across Massachusetts" color="blue" />
        <p>
          Rideshare travel flows in and out of almost every city and town in Massachusetts. While TNC drivers take passengers all over the Commonwealth, some trips are more common than others. Also, patterns of use for ridesharing can vary greatly from town to town. For example, only 11% of TNC rides that started in Truro ended in Truro. Meanwhile, 84% of trips that started in Provincetown ended in Provincetown. This discrepancy suggests that TNC services are used much differently even across cities and towns that are geographically next to, or close to, each other.
          <br />
          <br />
          To explore the data yourself, type a city or town into the search bar below. The map on the left shows the municipal destination for rides that began in the city or town you type. The map on the right shows the municipal origin of rides that ended in the city or town you type.
        </p>
        <div className="map-print">
          <div className="map-control-wrapper">
            <div className="input-text-container">
              <InputTextFuzzy
                label="Type a city or town below to see how many rides flowed in and out"
                keys={['text']}
                options={typeaheadArray}
                placeholder="Search by municipality, e.g. Boston"
                id="org-typeahead"
                inputId="input-org-typeahead"
                selected=""
                onSuggestionClick={this.handleMuniSelection}
              />
            </div>
            <div className="button-zoom-container">
              <IconButton
                size="small"
                info="Zoom in"
                onClick={this.handleZoomIn}
                icon="zoom-in"
                classes={['button-zoom']}
              />
              <IconButton
                size="small"
                info="Zoom out"
                onClick={this.handleZoomOut}
                icon="zoom-out"
                classes={['button-zoom']}
              />
              <IconButton
                size="small"
                info="Zoom out to full state"
                text="Reset"
                onClick={this.handleReset}
                icon="refresh"
                classes={['button-zoom']}
              />
            </div>
          </div>
          <FlowContext.Provider value={this.state}>
            <div className="wrapperLegendStyles row">
              <span className="legendTitle">Rides</span>
              <LegendThreshold
                scale={colorScale}
                direction="row"
                labelMargin="1px 5px"
                shape="rect"
                labelFormat={customFormat0}
                itemDirection="column"
                shapeWidth="100%"
                shapeMargin="1px"
                nullLabel="0"
                nullColor={() => '#eaeaea'}
              />
            </div>
            <div className="wrapperSplitStyles">
              <div className="wrapperSplitMap">
                <LinkedMap
                  mapTitle={`In 2019, <strong>${customFormat0(selectedOriginTrips)}</strong> rideshare rides <strong>started</strong> in <strong>${toProperCase(selectedTown)}</strong>.`}
                  showZoom
                  dataField={selectedTown}
                  dataCentroid={selectedCentroid}
                  dataDX={selectedDX}
                  dataDY={selectedDY}
                  dataBase={selectedOriginTrips}
                  dataUnits="trips"
                  dataFormat={customFormat0}
                  scaleBreaks={breaks}
                  scaleColors={colorRange}
                  allData={matrixDataTransposed}
                  geography={geography}
                  legend={{ showLegend: false }}
                  tooltip={{ dataLabel: `Rides to ${selectedTown}` }}
                  mapIndex={0}
                  otherMapIndex={[1]}
                  nullValue={0}
                />
              </div>
              <div className="wrapperSplitMap">
                <LinkedMap
                  showZoom
                  mapTitle={`In 2019, <strong>${customFormat0(selectedDestinationTrips)}</strong> rideshare rides <strong>ended</strong> in <strong>${toProperCase(selectedTown)}</strong>.`}
                  dataField={selectedTown}
                  dataCentroid={selectedCentroid}
                  dataDX={selectedDX}
                  dataDY={selectedDY}
                  dataBase={selectedDestinationTrips}
                  dataUnits="trips"
                  dataFormat={customFormat0}
                  scaleBreaks={breaks}
                  scaleColors={colorRange}
                  allData={matrixData}
                  geography={geography}
                  legend={{ showLegend: false }}
                  tooltip={{ dataLabel: `Rides from ${selectedTown}` }}
                  mapIndex={1}
                  otherMapIndex={[0]}
                  nullValue={0}
                />
              </div>
            </div>
          </FlowContext.Provider>
        </div>
      </Fragment>
    );
  }
}

FlowSection.propTypes = {
  allData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  geography: PropTypes.object,
  defaultCenter: PropTypes.array,
  defaultZoom: PropTypes.number,
  defaultDisablePan: PropTypes.bool,
  defaultCentroid: PropTypes.array,
  defaultTown: PropTypes.string,
  areas: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    coordinates: PropTypes.array,
    zoom: PropTypes.number
  })),
  annotations: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    strokeWidth: PropTypes.number,
    coordinates: PropTypes.array,
    dx: PropTypes.number,
    dy: PropTypes.number
  }))
};

FlowSection.defaultProps = {
  defaultCenter: [-71.63627925574303, 42.13276308419552],
  defaultZoom: 1.1,
  defaultDisablePan: true
};

export default FlowSection;
