import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@massds/mayflower-react';

const IconButton = (button) => {
  const onClickCallback = (e) => {
    if (typeof button.onClick === 'function') {
      e.preventDefault();
      button.onClick(e);
    }
  };
  const buttonClasses = button.classes ? `${button.classes.join(' ')} ` : '';
  return(
    <button
      className={buttonClasses}
      type="button"
      href={button.href}
      title={button.info}
      aria-label={button.info}
      onClick={(e) => onClickCallback(e)}
      disabled={button.disabled}
    >
      <Icon name={button.icon} svgHeight={20} svgWidth={20} />
      {button.text && ` ${button.text}`}
    </button>
  );
};

IconButton.propTypes = {
  /** Custom click handler function. */
  onClick: PropTypes.func,
  /** When populated with a url, this component renders a <a> vs a <button> */
  href: PropTypes.string,
  /** The text which renders in the standard browser tooltip on hover */
  info: PropTypes.string,
  /** Button or link text */
  text: PropTypes.string,
  /** HTML <button> 'type' attribute  */
  type: PropTypes.oneOf(['submit', 'reset', 'button', '']),
  /** Create a smaller button */
  size: PropTypes.oneOf(['', 'small', 'large']),
  /** Themes correspond to site color scheme i.e. sass variables */
  theme: PropTypes.oneOf(['', 'c-primary-alt', 'c-highlight', 'c-gray-dark']),
  /** Button usage */
  usage: PropTypes.oneOf(['', 'secondary', 'tertiary', 'quaternary']),
  /** Set button to disabled */
  disabled: PropTypes.bool
};

// Only set defaults for the configuration variables which need to be opted in to activate.
IconButton.defaultProps = {
  href: '',
  type: '',
  size: '',
  theme: '',
  usage: '',
  disabled: false
};

export default IconButton;
