import $ from 'jquery';

export const scrollToSection = (targets) => {
  let $id;
  $(targets).each(function () {
    // eslint-disable-next-line no-undef
    if ($(window).scrollTop() >= ($(this).offset().top) - 100) {
      $id = $(this).attr('id');
    }
  });
  return($id);
};

export const animatePageScroll = (target, offset, anchorId) => {
  const top = $(anchorId).offset().top - offset;
  $('html, body').animate({
    scrollTop: top
  }, 500);
};
