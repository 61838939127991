import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const FootNoteLink = ({ index }) => (
  <a id={`footnoteref${index}`} href={`#footnotemsg${index}`} className="page-scroll" aria-describedby="footnote-label">
    <span className="sr-only">see footnote</span>
    <sup>
      [
      {index}
      ]
    </sup>
  </a>
);

FootNoteLink.propTypes = {
  index: PropTypes.number
};

export default FootNoteLink;
