import React, { Fragment } from 'react';
import { ColoredHeading } from '@massds/mayflower-react';
import FootNoteLink from '../components/FootNote/FootNoteLink';

const SpeedSection = () => (
  <Fragment>
    <ColoredHeading text="Speed and Length of Rides" color="blue" />
    <p>
      The average ride in Massachusetts lasted 15.6 minutes and traveled 4.6 miles at 17.7 miles per hour.
      <FootNoteLink index={6} />
      <br />
      Rides in Central and Western Massachusetts tended to move faster than in Eastern Massachusetts. Municipalities that averaged speeds of more than 40 miles per hour were mainly located in Central and Western Massachusetts.
      <br />
      The slowest travel speeds were in densely populated cities and towns. Watertown, Cambridge, Somerville, and Brookline all had average ride speeds of less than 18 miles per hour.
    </p>
  </Fragment>
);

export default SpeedSection;
