import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CompHeading } from '@massds/mayflower-react';
import ThresholdScaleMap from '../components/ThresholdScaleMap';

import { deltaMapsOptions } from './mapOptions.data';

const DeltaTripsMap = (props) => {
  const {
    allData, geography, areas
  } = props;
  const [option, setOption] = useState(deltaMapsOptions[2].origin);
  const newData = allData.map((item) => {
    const newItem = { ...item };
    if (Number(item.ORIGIN_TRIPS_2019) <= 50 || Number(item.ORIGIN_TRIPS_2017) <= 50) {
      newItem['ORIGIN_DELTA_PRCT_2017-2019'] = -99;
    }
    if (Number(item.ORIGIN_TRIPS_2018) <= 50 || Number(item.ORIGIN_TRIPS_2018) <= 50) {
      newItem['ORIGIN_DELTA_PRCT_2018-2019'] = -99;
    }
    return newItem;
  });
  return(
    <Fragment>
      <CompHeading title={option.title} level={3} centered />
      <aside className="sidebar">
        In prior years, ridesharing was mainly confined to larger cities. However the ride data from 2018 and 2019 demonstrates that is no longer true. Ridesharing companies reported that in 2019, rides more than doubled in several towns with fewer than 8,000 residents, including Avon (35,208 rides), Nahant (15,170 rides), Dunstable (1,151 rides), Rochester (653 rides), and Shirley (3,265 rides). Use this map to see where ridership grew most. You can explore ridesharing in different parts of the state by selecting a region, using the magnifying glass, and by clicking/tapping and dragging.
      </aside>

      <div className="page-content">
        <div className="map-print">
          <div className="wrapperButtonStyles">
            {
              deltaMapsOptions.map((opt, i) => (
                <Fragment>
                  {
                    // add break after the the third button
                    (i === 3) && <br />
                  }
                  <Button
                    text={opt.name}
                    info={`Change map to display ${option.name}`}
                    theme={opt.origin.theme}
                    usage={opt.origin.field === option.field ? '' : 'secondary'}
                    onClick={() => setOption(opt.origin)}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`deltamap_button-${i}`}
                  />
                </Fragment>
              ))
            }
          </div>
          <ThresholdScaleMap
            showZoom
            geoDisplayField="TOWN"
            dataField={option.field}
            dataUnits={option.units}
            dataFormat={option.dataFormat}
            scaleBreaks={option.scaleBreaks}
            scaleColors={option.colorScale}
            allData={newData}
            nullValue={-99}
            nullValueMessage="<50 rides started in 2017"
            geography={geography}
            legend={{
              direction: window.innerWidth > 620 ? 'column' : 'row',
              title: option.legendTitle,
              itemDirection: window.innerWidth > 620 ? 'row' : 'column'
            }}
            tooltip={{ dataLabel: option.dataLabel }}
            areas={areas}
          />
        </div>
      </div>
    </Fragment>
  );
};

DeltaTripsMap.propTypes = {
  allData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  geography: PropTypes.object,
  defaultCenter: PropTypes.array,
  defaultZoom: PropTypes.number,
  defaultDisablePan: PropTypes.bool,
  areas: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    coordinates: PropTypes.array,
    zoom: PropTypes.number
  }))
};

export default DeltaTripsMap;
