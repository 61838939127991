import {
  schemeYlGn,
  schemeBuPu,
  schemeYlGnBu,
  schemePuBuGn
} from 'd3-scale-chromatic';
import {
  customFormat0,
  customFormatPercent,
  customFormat1
} from '../utils';
import content from '../data/Content.data';

const {
  data: {
    boston,
    cambridge,
    hadley,
    amherst,
    nantucket
  }
} = content;

export const mapAreas = [{
  name: 'Greater Boston',
  coordinates: [-71.03627925574303, 42.33276308419552],
  zoom: 4
}, {
  name: 'Cape Cod',
  coordinates: [-70.388761, 41.681497],
  zoom: 2.15
}, {
  name: 'North Shore',
  coordinates: [-70.982613, 42.655939],
  zoom: 4
}, {
  name: 'Central Mass',
  coordinates: [-72.217152, 42.419665],
  zoom: 2.5
}, {
  name: 'Western Mass',
  coordinates: [-73.014503, 42.399396],
  zoom: 3
}];

export const deltaMapsOptions = [{
  name: '2017 Total Rides',
  origin: {
    field: 'ORIGIN_TRIPS_2017',
    legendTitle: '2017 Total Origin Rides',
    title: '2017 Total Rides Started by Municipality',
    colorScale: schemeBuPu[9],
    scaleBreaks: [20, 50, 2500, 7000, 20000, 50000, 150000, 250000],
    dataFormat: customFormat0,
    dataLabel: '2017',
    units: 'origin rides'
  },
  destination: {
    field: 'DESTINATION_TRIPS_2017',
    legendTitle: '2017 Total Destination Rides',
    title: '2017 Total Rides Ended by Municipality'
  }
}, {
  name: '2018 Total Rides',
  origin: {
    field: 'ORIGIN_TRIPS_2018',
    legendTitle: '2018 Total Origin Rides',
    title: '2018 Total Rides Started by Municipality',
    colorScale: schemeBuPu[9],
    scaleBreaks: [20, 50, 2500, 7000, 20000, 50000, 150000, 250000],
    dataFormat: customFormat0,
    dataLabel: '2018',
    units: 'origin rides'
  },
  destination: {
    field: 'DESTINATION_TRIPS_2018',
    legendTitle: '2018 Total Destination Rides',
    title: '2018 Total Rides Ended by Municipality'
  }
}, {
  name: '2019 Total Rides',
  origin: {
    field: 'ORIGIN_TRIPS_2019',
    legendTitle: '2019 Total Origin Rides',
    title: '2019 Total Rides Started by Municipality',
    colorScale: schemeBuPu[9],
    scaleBreaks: [20, 50, 2500, 7000, 20000, 50000, 150000, 250000],
    dataFormat: customFormat0,
    dataLabel: '2019',
    units: 'origin rides'
  },
  destination: {
    field: 'DESTINATION_TRIPS_2019',
    legendTitle: '2019 Total Destination Rides',
    title: '2019 Total Rides Ended by Municipality'
  }
}, {
  name: '2017—2019 Percent Change',
  origin: {
    field: 'ORIGIN_DELTA_PRCT_2017-2019',
    theme: 'c-primary-alt',
    legendTitle: 'Percent Change in Rides Started',
    title: 'Percent Change in Rides Started by Municipality',
    colorScale: schemeYlGn[9],
    scaleBreaks: [-50, 0, 5, 50, 100, 200, 300, 400],
    dataFormat: customFormatPercent,
    dataLabel: 'Percent Growth in Origin Rides',
    units: ''
  },
  destination: {
    field: 'DESTINATION_DELTA_PRCT_2017-2019',
    legendTitle: 'Percent Change in Destination Rides',
    title: 'Percent Change in Rides Ended by Municipality'
  }
}, {
  name: '2018—2019 Percent Change',
  origin: {
    field: 'ORIGIN_DELTA_PRCT_2018-2019',
    theme: 'c-primary-alt',
    legendTitle: 'Percent Change in Rides Started',
    title: 'Percent Change in Rides Started by Municipality',
    colorScale: schemeYlGn[9],
    scaleBreaks: [-50, 0, 5, 50, 100, 200, 300, 400],
    dataFormat: customFormatPercent,
    dataLabel: 'Percent Growth in Origin Rides',
    units: ''
  },
  destination: {
    field: 'DESTINATION_DELTA_PRCT_2018-2019',
    legendTitle: 'Percent Change in Destination Rides',
    title: 'Percent Change in Rides Ended by Municipality'
  }
}];


export const speedMapsOptions = [{
  name: 'Miles per Hour',
  origin: {
    field: 'AVG_MILE_PER_HOUR_ORIGIN_2019',
    scale: [20, 25, 30, 35, 40],
    title: 'Speed',
    units: 'miles per hour',
    legendTitle: 'Average Speed (miles per hour)',
    colorScale: ['#E6585E', '#FF906B', '#FCCD8E', '#DAEBC7', '#8ABA79', '#688B5B'],
    dataLabel: ''
  }
}, {
  name: 'Miles from Start',
  origin: {
    field: 'AVG_MILES_FROM_ORIGIN_2019',
    scale: [5, 7.5, 10, 12.5, 15, 30],
    title: 'Miles Travelled',
    units: 'miles',
    legendTitle: 'Average Miles',
    colorScale: ['#FFFFD9', '#FEE69F', '#FECB65', '#FEA644', '#EE8231', '#D26222', '#9A4723'],
    // colorScale: ['#C71B3D', '#E6585E', '#FF906B', '#FCCD8E', '#DAEBC7', '#8ABA79', '#688B5B'],
    dataLabel: ''
  }
}, {
  name: 'Minutes from Start',
  origin: {
    field: 'AVG_MINS_FROM_ORIGIN_2019',
    scale: [12.5, 15, 17.5, 20, 25, 30],
    title: 'Time Spent Travelling ',
    units: 'minutes',
    legendTitle: 'Average Minutes',
    colorScale: ['#FFFFD9', '#FEE69F', '#FECB65', '#FEA644', '#EE8231', '#D26222', '#9A4723'],
    // colorScale: ['#C71B3D', '#E6585E', '#FF906B', '#FCCD8E', '#DAEBC7', '#8ABA79', '#688B5B'],
    dataLabel: ''
  }
}];

export const totalMapsOptions = [{
  name: 'Total Rides Started',
  origin: {
    field: 'ORIGIN_TRIPS_2019',
    legendTitle: 'Total Rides Started',
    title: '2019 Total Rides Started by Municipality',
    units: 'origin rides',
    colorScale: schemeBuPu[9],
    scaleBreaks: [20, 50, 2500, 7000, 20000, 50000, 150000, 250000],
    dataFormat: customFormat0,
    dataLabel: ''
  }
}, {
  name: 'Total Rides Ended',
  origin: {
    field: 'DESTINATION_TRIPS_2019',
    legendTitle: 'Total Rides Ended',
    title: '2019 Total Rides Ended by Municipality',
    units: 'destination rides',
    colorScale: schemeYlGnBu[9],
    scaleBreaks: [20, 50, 2500, 7000, 20000, 50000, 150000, 250000],
    dataFormat: customFormat0,
    dataLabel: ''
  }
}, {
  name: 'Rides Started Per Person',
  origin: {
    field: 'ORIGIN_TRIPS_PP_2019',
    legendTitle: 'Rides Started (per person)',
    title: '2019 Total Rides Started Per Person by Municipality',
    units: 'rides per capita',
    colorScale: schemePuBuGn[8],
    scaleBreaks: [0.1, 0.3, 0.5, 1.0, 1.75, 3.0, 15],
    dataFormat: customFormat1,
    dataLabel: '',
    annotations: [{
      label: `Cambridge (${cambridge.originPP})`,
      strokeWidth: 1,
      coordinates: [-71.115787, 42.373960],
      dx: 80,
      dy: -40
    }, {
      label: `Boston (${boston.originPP})`,
      strokeWidth: 1,
      coordinates: [-71.055787, 42.333960],
      dx: 80,
      dy: 0
    }, {
      label: `Nantucket (${nantucket.originPP})`,
      strokeWidth: 1,
      coordinates: [-70.232976, 41.285821],
      dx: 30,
      dy: -40
    }, {
      label: `Hadley (${hadley.originPP})`,
      strokeWidth: 1,
      coordinates: [-72.59, 42.39645],
      dx: 30,
      dy: 200
    }, {
      label: `Amherst (${amherst.originPP})`,
      strokeWidth: 1,
      coordinates: [-72.54422, 42.42781],
      dx: 80,
      dy: 160
    }]
  }
}, {
  name: 'Rides Ended Per Person',
  origin: {
    field: 'DESTINATION_TRIPS_PP_2019',
    legendTitle: 'Rides Ended (per person)',
    title: '2019 Total Rides Ended Per Person by Municipality',
    units: 'rides per capita',
    colorScale: schemePuBuGn[8],
    scaleBreaks: [0.1, 0.3, 0.5, 1.0, 1.75, 3.0, 15],
    dataFormat: customFormat1,
    dataLabel: '',
    annotations: [{
      label: `Cambridge (${cambridge.destinationPP})`,
      strokeWidth: 1,
      coordinates: [-71.115787, 42.373960],
      dx: 80,
      dy: -40
    }, {
      label: `Boston (${boston.destinationPP})`,
      strokeWidth: 1,
      coordinates: [-71.055787, 42.333960],
      dx: 80,
      dy: 0
    }, {
      label: `Nantucket (${nantucket.destinationPP})`,
      strokeWidth: 1,
      coordinates: [-70.232976, 41.285821],
      dx: 30,
      dy: -40
    }, {
      label: `Hadley (${hadley.destinationPP})`,
      strokeWidth: 1,
      coordinates: [-72.59, 42.39645],
      dx: 30,
      dy: 200
    }, {
      label: `Amherst (${amherst.destinationPP})`,
      strokeWidth: 1,
      coordinates: [-72.54422, 42.42781],
      dx: 80,
      dy: 160
    }]
  }
}];
